import { action, makeAutoObservable, observable } from 'mobx'
import { api } from 'utils/config'

class MediaQuery {
  minWidth_2200: boolean | undefined = undefined


  constructor() {
    makeAutoObservable(this)
  }

  
  setMinWidth_2200(value: boolean | undefined) {
    this.minWidth_2200 = value
  }
}

export default new MediaQuery()
