import React, { FC, useState } from 'react'
import Slide from '@mui/material/Slide'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Avatar from '@mui/material/Avatar'

import { useStore } from 'store'
import { observer } from 'mobx-react'

import defaultUserPhoto from 'sources/images/defaultUser.svg'
import { Drawer } from './Drawer'


interface IProps {
}

export const DrawerMenuPWA: FC<IProps> = observer(props => {
  const { profile } = useStore()

  const [open, setOpen] = useState(false)

  const trigger = useScrollTrigger()

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return
    }

    setOpen(open)
  }

  const userAvatarImage = profile.selectedUser.ImageLink ? profile.selectedUser.ImageLink : defaultUserPhoto

  return (
    <ScopedCssBaseline>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="fixed" color="primary" sx={{ top: 0, bottom: 'auto' }}>
          <Toolbar sx={{
            paddingLeft: '24px',
            paddingRight: '24px',
            justifyContent: 'space-between',
            backgroundColor: 'var(--mainColorRevert)',
            minHeight: {xs: '56px', sm: '56px'}
          }}>
            <IconButton color="inherit"
                        aria-label={`open menu`}
                        onClick={toggleDrawer(true)}
            >
              <Avatar
                alt="avatar"
                src={userAvatarImage}
                sx={{ width: 24, height: 24 }}
              />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>
      <Drawer open={open} toggleDrawer={toggleDrawer}/>
    </ScopedCssBaseline>
  )
})
