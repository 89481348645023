import { convertToRaw, DraftInlineStyle, EditorState, Modifier } from 'draft-js'
import { action, makeAutoObservable, observable } from 'mobx'
import { v4 } from 'uuid'

import { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag'

import {
  apiError,
  checkBadWords,
  checkNumberOfLineBreaks,
  createPostFileType,
  getEditorStateAsString,
  getPostLengthFromEditorState,
  getPostTextFromEditorState,
  TRenderType,
} from '../utils'
import { api } from 'utils/config'

import { resetBlockWithType } from '../components/CustomEditor/util'
import { toast } from 'App'
import { Block as EditorBlockType } from 'components/CustomEditor/util/constants'

import type {
  IBlogpostPreviewData,
  IIpfsFiles,
  IPublication,
  ISendPublication,
} from 'models'
import { ISendVideoPublication } from 'models'

import ProfileStore from 'store/profile'

import Widget from './widget'

export interface ITransactionCreateBlock {
  blockKey: string
  blockData: ISendPublication[]
  isAdultContent: boolean
  postOnX: boolean
}

export interface IMintData {
  data: ITransactionCreateBlock[] | null
  superPostPublicText: EditorState | null
  postType: PostType
}

export enum PostType {
  Blockchain,
  Encrypted,
  Superpost,
  Blogpost,
  Freepost,
  Streampost,
}

export enum VideoPostType {
  Blockchain,
  Freepost,
}

export const getBlockchainPostType = (type: PostType) => {
  switch (type) {
    case PostType.Blockchain:
      return 'Publication'
    case PostType.Encrypted:
      return 'Encrypted_publication'
    case PostType.Superpost:
      return 'Superpost'
    case PostType.Blogpost:
      return 'Blogpost'
    case PostType.Streampost:
      return 'Streampost'
    default:
      return 'Publication'
  }
}

// const emptyEditorState = EditorState.createEmpty();
// const contentStateWithBlockType = Modifier.setBlockType(
//   emptyEditorState.getCurrentContent(),
//   emptyEditorState.getSelection(),
//   EditorBlockType.H1
// );
// const editorState = EditorState.push(emptyEditorState, contentStateWithBlockType, 'change-block-type');
// or
// const editorState = resetBlockWithType(emptyEditorState, EditorBlockType.H1)

// const defaultTransactionCreateBlockBlogpost = {
//   blockKey: '0',
//   blockData: [{ EditorData: resetBlockWithType(EditorState.createEmpty(), EditorBlockType.H1), Id: '0', IpfsFiles: [], IsEncrypted: false }],
//   isAdultContent: false
// }

export const defaultTransactionCreateBlock = {
  blockKey: '0',
  blockData: [
    {
      EditorData: EditorState.createEmpty(),
      Id: '0',
      IpfsFiles: [],
      IsEncrypted: false,
    },
  ],
  isAdultContent: false,
  postOnX: false,
}

export const defaultMintVideoPost = {
  EditorData: EditorState.createEmpty(),
  Id: '0',
  IpfsFiles: [
    {
      Id: v4(),
      IsAdultContent: false,
      PostOnX: false,
      FileLink: '',
      FileType: createPostFileType.video,
      FileExtension: '',
      VideoPreview: '',
      VideoAudioTitle: EditorState.createEmpty(),
      VideoAudioDescription: EditorState.createEmpty(),
    },
  ],
  IsDefault: true,
  PostType: 0,
}

interface IBlogPostFiles {
  id: string
  file: File | null
  src?: string
}

class CreatePostStore {
  transactionCreateBlocks: ITransactionCreateBlock[] = [
    defaultTransactionCreateBlock,
  ]
  threadPublicationId: number | undefined = undefined
  mintData: IMintData | null = null
  mintVideoPost: ISendVideoPublication = defaultMintVideoPost
  blogPostFiles: IBlogPostFiles[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setBlogPostFiles = (value: IBlogPostFiles[]) => {
    this.blogPostFiles = value
  }

  setMintData = (mintData: IMintData | null) => {
    this.mintData = mintData
  }

  setMintVideoPost = (value: ISendVideoPublication) => {
    this.mintVideoPost = value
  }

  setTransactionCreateBlocksDefault = () => {
    this.transactionCreateBlocks = [defaultTransactionCreateBlock]
  }

  setTransactionCreateBlocks = (value: ITransactionCreateBlock[]) => {
    this.transactionCreateBlocks = value
  }

  setThreadPublicationId = (value?: number) => {
    this.threadPublicationId = value
  }

  async createPost(
    data: ISendPublication[],
    BlockchainId: number,
    transactionHash: string | null,
    PublicationId: number | undefined,
    NetworkType: number,
    isGetBlockchainId?: boolean,
    renderType?: TRenderType
  ) {
    const selectedUser = ProfileStore.selectedUser

    try {
      const mainPost = await api.post(
        `api/${
          renderType === 'widget' ? 'Widget/createPublication' : 'Publication'
        }`,
        {
          Text: isGetBlockchainId
            ? ''
            : data[0].Text
            ? data[0].Text
            : getEditorStateAsString(data[0].EditorData),
          BlockchainId: BlockchainId,
          IsThread: data.length > 1,
          IsEncrypted: Boolean(data[0].IsEncrypted),
          IsAdultContent: data[0].IsAdultContent,
          PostOnX: data[0].PostOnX,
          TaggedNickname: data[0].IsEncrypted ? data[0].TaggedNickname : '',
          ThreadNumber: 0,
          IpfsFiles: isGetBlockchainId
            ? []
            : this.convertIpfsFiles(data[0].IpfsFiles, true),
          PublicationKey: transactionHash,
          PublicationId: PublicationId,
          IsSuperpost: data[0].IsSuperpost,
          IsStreampost: data[0].IsStreampost,
          PublicText:
            isGetBlockchainId || !data[0].IsSuperpost
              ? ''
              : getEditorStateAsString(data[0].PublicText as EditorState),
          PriceToOpenPost: data[0].IsSuperpost ? data[0].PriceToOpenPost : 0,
          IsBlogpost: data[0].IsBlogpost,
          BlogpostTitle: data[0].BlogpostTitle
            ? getEditorStateAsString(data[0].BlogpostTitle)
            : undefined,
          BlogpostDescription: data[0].BlogpostDescription
            ? getEditorStateAsString(data[0].BlogpostDescription)
            : undefined,
          BlogpostPreviewLink: data[0].BlogpostPreviewLink,
          BlogpostPreviewLinkCdn: data?.[0]?.BlogpostPreviewLinkCdn,
          NetworkType,
        },
        {
          headers: renderType === 'widget' ? Widget.getWidgetAuthHeaders() : {},
          params: renderType === 'widget' ? Widget.getWidgetParams() : {},
        }
      )

      if (isGetBlockchainId) {
        return mainPost.data
      }

      if (mainPost.status === 400) {
        return toast({
          type: 'error',
          message: `${mainPost.data.Title}. ${mainPost.data.Description}`,
        })
      }
      if (mainPost.status === 200) {
        this.setThreadPublicationId(Number(mainPost.data.Id))
        for (let i = 1; i <= data.length - 1; i++) {
          data[i].Text = getEditorStateAsString(data[i].EditorData)
          data[i].ThreadMainMessage = mainPost.data.Id
          data[i].ThreadNumber = i
          //@ts-ignore
          data[i].IpfsFiles = this.convertIpfsFiles(data[i].IpfsFiles)
          //Delete
          data[i].UserId = selectedUser.Id
          data[i].IsThread = true
          data[i].IsAdultContent = Boolean(data[i].IsAdultContent)
          data[i].PostOnX = Boolean(data[i].PostOnX)
          //@ts-ignore
          delete data[i].Id
          //@ts-ignore
          delete data[i].EditorData
          //New
          // data[i].PublicationId = mainPost.data.Id // or PublicationId
          const threadPost = await api.post('api/Publication', data[i])
          this.setThreadPublicationId(Number(threadPost.data.Id))
        }
        return mainPost.data
      }

      if (mainPost.status !== 200 && mainPost.status !== 400) {
        return toast({
          type: 'error',
          message:
            mainPost.data && mainPost.data.Description
              ? mainPost.data.Description
              : 'Something went wrong. Please try again.',
        })
      }
    } catch (error: any) {
      toast({
        type: 'error',
        message: error.message
          ? error.message
          : 'Something went wrong. Please try again.',
      })
    }
  }

  async createFreePost(data: ISendPublication[], renderType?: TRenderType) {
    const response = await api.post(
      `api/${
        renderType === 'widget' ? 'Widget' : 'Publication'
      }/createFreePost`,
      {
        Text: data[0].Text
          ? data[0].Text
          : getEditorStateAsString(data[0].EditorData),
        IsAdultContent: data[0].IsAdultContent,
        PostOnX: data[0].PostOnX,
        IpfsFiles: this.convertIpfsFiles(data[0].IpfsFiles, true),
      },
      {
        headers: renderType === 'widget' ? Widget.getWidgetAuthHeaders() : {},
        params: renderType === 'widget' ? Widget.getWidgetParams() : {},
      }
    )

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async uploadLargeFile(file: File) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await api.post(`api/Download/uploadLargeFile`, formData)

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async upload3DImage(file: File) {
    const formData = new FormData()
    formData.append('files', file)

    const response = await api.post(`api/Download/convert3DObject`, formData)

    if (response.status !== 200) apiError(response)

    return response.data
  }

  convertIpfsFiles = (
    value: IIpfsFiles[],
    setPreviewFromDefault: boolean = true
  ) => {
    const getText = (data: EditorState) => {
      if (typeof data === 'string') {
        return data
      } else {
        return JSON.stringify(convertToRaw(data.getCurrentContent()))
      }
    }

    return value.map(({ Id, ...rest }) =>
      rest.VideoAudioTitle && rest.VideoAudioDescription
        ? {
            ...rest,
            VideoAudioTitle: getText(rest.VideoAudioTitle),
            VideoAudioDescription: getText(rest.VideoAudioDescription),
            VideoPreview: rest.VideoPreview
              ? rest.VideoPreview
              : setPreviewFromDefault
              ? rest.DefaultVideoPreview
              : '',
          }
        : rest
    )
  }

  //   PUBLICATION RULES
  isPostEmptyListToast = (list: ISendPublication[], message?: string) => {
    if (
      list.find(
        item =>
          !getPostLengthFromEditorState(item.EditorData) &&
          item.IpfsFiles.length === 0
      )
    ) {
      toast({
        type: 'warning',
        message: message
          ? message
          : 'It looks like your post is empty. Please write something before posting',
      })
      return true
    }
    return false
  }

  isRightQuantitySymbolsToast = (quantity: number, message?: string) => {
    if (quantity > 5000) {
      toast({
        type: 'warning',
        message: message
          ? message
          : 'The number of characters exceeds the limit of 5000',
      })

      return true
    }
    return false
  }

  isTwoLineBreaksTogetherListToast = (
    list: ISendPublication[],
    message?: string
  ) => {
    if (
      list.some(item =>
        checkNumberOfLineBreaks(getPostTextFromEditorState(item.EditorData))
      )
    ) {
      toast({
        type: 'warning',
        message: message
          ? message
          : 'You are not allowed to use more than 2 line breaks together',
      })
      return true
    }
    return false
  }

  isContainBadWordsListToast = (list: ISendPublication[], message?: string) => {
    if (
      list.some(item =>
        checkBadWords(getPostTextFromEditorState(item.EditorData))
      )
    ) {
      toast({
        type: 'warning',
        message: message
          ? message
          : 'You are not allowed to use prohibited words',
      })
      return true
    }
    return false
  }

  isWrongAudioVideoAdditionalInfoToast = (ipfsFiles: IIpfsFiles[]) => {
    if (ipfsFiles.length > 0) {
      const fileTitleWrongLength = ipfsFiles.find(
        file =>
          file.VideoAudioTitle &&
          (getPostLengthFromEditorState(file.VideoAudioTitle) === 0 ||
            getPostLengthFromEditorState(file.VideoAudioTitle) > 200)
      )
      if (fileTitleWrongLength) {
        toast({
          type: 'warning',
          message:
            fileTitleWrongLength.VideoAudioTitle &&
            getPostLengthFromEditorState(fileTitleWrongLength.VideoAudioTitle) >
              200
              ? 'The number of characters in the title exceed the limit of 200'
              : `There is no title for the ${fileTitleWrongLength.FileType}`,
        })
        return true
      }
      const fileDescriptionWrongLength = ipfsFiles.find(
        file =>
          file.VideoAudioDescription &&
          getPostLengthFromEditorState(file.VideoAudioDescription) > 500
      )
      if (fileDescriptionWrongLength) {
        toast({
          type: 'warning',
          message:
            'The number of characters in the description exceed the limit of 500',
        })
        return true
      }

      const fileTitleOnlySpace = ipfsFiles.find(
        file =>
          file.VideoAudioTitle &&
          getPostLengthFromEditorState(file.VideoAudioTitle) <=
            (getPostTextFromEditorState(file.VideoAudioTitle).match(/ /g) || [])
              .length
      )
      if (fileTitleOnlySpace) {
        toast({
          type: 'warning',
          message: 'Invalid title!',
        })
        return true
      }

      const fileDescriptionWrongQuantityHashtags = ipfsFiles.find(
        file =>
          file.VideoAudioDescription &&
          new Set(
            extractHashtagsWithIndices(
              getPostTextFromEditorState(file.VideoAudioDescription)
            ).map(item => item.hashtag)
          ).size < 5
      )
      if (fileDescriptionWrongQuantityHashtags) {
        toast({
          type: 'warning',
          message: 'The description must contain at least 5 unique hashtags',
        })
        return true
      }

      const fileTitleWithBadWords = ipfsFiles.find(
        file =>
          file.VideoAudioTitle &&
          checkBadWords(getPostTextFromEditorState(file.VideoAudioTitle))
      )
      const fileDescriptionWithBadWords = ipfsFiles.find(
        file =>
          file.VideoAudioDescription &&
          checkBadWords(getPostTextFromEditorState(file.VideoAudioDescription))
      )
      if (fileTitleWithBadWords || fileDescriptionWithBadWords) {
        toast({
          type: 'warning',
          message: 'You are not allowed to use prohibited words',
        })
        return true
      }
      const fileTitleWrongLineBreaks = ipfsFiles.find(
        file =>
          file.VideoAudioTitle &&
          checkNumberOfLineBreaks(
            getPostTextFromEditorState(file.VideoAudioTitle)
          )
      )
      const fileDescriptionWrongLineBreaks = ipfsFiles.find(
        file =>
          file.VideoAudioDescription &&
          checkNumberOfLineBreaks(
            getPostTextFromEditorState(file.VideoAudioDescription)
          )
      )
      if (fileTitleWrongLineBreaks || fileDescriptionWrongLineBreaks) {
        toast({
          type: 'warning',
          message:
            'You are not allowed to use more than 2 line breaks together',
        })
        return true
      }

      return false
    } else {
      return false
    }
  }
}

export default new CreatePostStore()
