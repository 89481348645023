import React, { FC } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'

import styles from './styles.module.scss'

interface IProps {
  children: React.ReactElement
  title: TooltipProps['title']
  placement: TooltipProps['placement']
  disablePortal?: boolean
  stopPropagation?: boolean
  isOpen?: boolean
  isInteractive?: boolean
}

//Resize
export const CustomTooltip: FC<IProps> = ({
  children,
  title,
  placement,
  disablePortal,
  stopPropagation,
  isOpen,
  isInteractive,
}) => {
  const popperProps = disablePortal
    ? {
        disablePortal: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window', // where "window" is the boundary
            },
          },
        },
      }
    : {}

  const handlerClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    stopPropagation && event.stopPropagation()
  }

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      classes={{ popper: styles.popper, popperArrow: styles.popperArrow }}
      PopperProps={popperProps}
      onClick={handlerClick}
      open={isOpen}
      interactive={isInteractive}
    >
      {children}
    </Tooltip>
  )
}

CustomTooltip.displayName = 'CustomTooltip'
