import { BadWords } from 'utils/const'

export const getRoundingValue = (a: number) => {
  if (typeof a !== 'number' || a === 0) return a+''
  let n = 0
  while (a >= 1000) {
    a /= 1000
    n++
  }
  return +a.toFixed(1) + (['', 'k', 'm', 'b', 't'][n] || '')
}

export const checkStringNumber = (value: string): boolean => {
  if (value.length === 0) return true
  if (value.length === 2 && value.charAt(0) === '0' && value.charAt(1) !== '.') return false
  // return !!value.match(/^[0-9][1-9]*[.]?[0-9]?$/)
  return !!value.match(/^[0-9][1-9]*[.]?[0-9]{0,2}$/)
}

export const checkBadWords = (value: string | undefined | null) => {
  if (value) {
    return BadWords.some(word => {
      const matches = [...value.matchAll(new RegExp('\\b(?:' + word + ')\\b', 'gi'))]
      return matches.length > 0
    })
    // const arr = value.trim().split(/[^a-zA-Z]+/);
    // // const res = arr.filter(x=>badWords.some(m=>m === x.toLowerCase()))
    // return arr.some(x => BadWords.some(m => m === x.toLowerCase()))
  }
  return false
}
