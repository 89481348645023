import { observable, action, makeAutoObservable } from 'mobx'

class LoadingStore {
  active: boolean = false
  loadingType?: 'upload'
  loadingProgress: number = 0

  constructor() {
    makeAutoObservable(this)
  }


  setLoading(value: boolean, type?: 'upload') {
    this.loadingProgress = 0
    this.loadingType = type
    this.active = value
  }


  setLoadingProgress(value: number) {
    this.loadingProgress = value
  }
}

export default new LoadingStore()
