import React, { CSSProperties, FC, Fragment, useEffect } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { CloseIconButtonPWA } from '../CloseIconButtonPWA'

import { Breakpoint, SxProps } from '@mui/system'
import { ReactComponent as CloseIcon } from 'sources/images/close.svg'

import styles from './styles.module.scss'

interface IProps {
  open: boolean,
  onClose?: () => void,
  children: React.ReactNode,
  disableOutsideClick?: boolean
  scroll?: DialogProps['scroll']
  dialogMode?: boolean
  paperSX?: SxProps
  title?: React.ReactNode
  titleSX?: SxProps
  subTitle?: React.ReactNode
  subTitleSX?: SxProps
  contentSX?: SxProps
  dialogTitle?: React.ReactNode
  dialogTitleSX?: SxProps
  dialogActions?: React.ReactNode
  dialogActionsSX?: SxProps
  dialogContentSX?: SxProps
  dialogContentTextSX?: SxProps
  closeButton?: boolean
  maxWidth?: Breakpoint | false
  fullScreen?: boolean
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ModalPWA: FC<IProps> = (
  {
    open,
    onClose,
    children,
    disableOutsideClick,
    scroll,
    dialogMode,
    paperSX,
    title,
    titleSX,
    subTitle,
    subTitleSX,
    contentSX,
    dialogTitle,
    dialogTitleSX,
    dialogActions,
    dialogContentSX,
    dialogContentTextSX,
    dialogActionsSX,
    closeButton,
    maxWidth = 'sm',
    fullScreen
  }) => {
  const theme = useTheme()
  const fullScreenMedia = useMediaQuery(theme.breakpoints.down('sm'))
  // const fullScreenMedia = useMediaQuery('(max-width:576px)');
  const isFullScreen = fullScreen || fullScreenMedia


  const descriptionElementRef = React.useRef<HTMLElement>(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  return (
    <Dialog
      fullScreen={isFullScreen}
      open={open}
      scroll={scroll}
      onClose={!disableOutsideClick ? onClose : undefined}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(22, 20, 50, 0.5)', backdropFilter: 'blur(4px)' } } }}
      PaperProps={{
        sx: {
          width: '100%',
          borderRadius: isFullScreen ? 0 : '17.5px',
          background: 'var(--boxColor)',
          boxShadow: isFullScreen ? 'none' : '-5px 5px 15px 0px rgba(0, 0, 0, 0.40)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...paperSX
        }
      }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {dialogMode
        ? <Fragment>
          <DialogTitle id="scroll-dialog-title"
                       sx={{
                         width: '100%',
                         fontFamily: 'Lato, sans-serif',
                         fontSize: '1.25rem',
                         padding: '34px 16px 16px',
                         textAlign: 'center',
                         fontWeight: 500,
                         color: 'white',
                         lineHeight: 'normal',
                         ...dialogTitleSX
                       }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent sx={{
            width: '100%',
            padding: 0,
            ...dialogContentSX
          }}>
            <Box
              sx={{
                height: '100%',
                padding: '0 16px',
                ...dialogContentTextSX
              }}
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              {children}
            </Box>
          </DialogContent>
          {dialogActions
            ? <DialogActions sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '16px 16px 34px',
              width: '100%',
              ...dialogActionsSX
            }}>
              {dialogActions}
            </DialogActions>
            : null}
        </Fragment>
        : <Fragment>
          {closeButton
            ? <>
              <CloseIconButtonPWA
                onClose={onClose}
                sx={{
                  position: 'sticky',
                  top: '10px',
                  left: 0,
                  margin: '10px auto 0 10px'
                }}/>
              {/*<button className={styles.closeButton} onClick={onClose}>*/}
              {/*  <CloseIcon className={styles.cross}/>*/}
              {/*</button>*/}
            </>
            : null}
          <Box className={styles.content} sx={contentSX}>
            {title ? <Box sx={titleSX} className={styles.title}>{title}</Box> : null}
            {subTitle ? <Box sx={subTitleSX} className={styles.subTitle}>{subTitle}</Box> : null}
            {children}
          </Box>
        </Fragment>
      }
    </Dialog>
  )
}
