import { action, makeAutoObservable, observable } from 'mobx'
import type { ILiveFeed, ILoadPage, IPublication } from 'models'
import { activeTabType, defaultLoadPage } from 'utils'
import { makePersistable } from 'mobx-persist-store'


export interface ISelectedProfilePageData {
  activeTab: activeTabType
  sortParameters: { sortBy: number, orderByDescending: boolean }
  posts: IPublication[]
  pinnedPost: IPublication | null
  postsStartTotalItems: number
  postsPageSettings: ILoadPage
  scrollPosition: number
}

export const defaultSelectedProfilePageData = {
  activeTab: activeTabType.myPosts,
  sortParameters: { sortBy: 0, orderByDescending: true },
  posts: [],
  pinnedPost: null,
  postsStartTotalItems: 0,
  postsPageSettings: defaultLoadPage,
  scrollPosition: 0
}

class PageActivities {
  selectedProfilePageData: ISelectedProfilePageData  = defaultSelectedProfilePageData

  scrollPosition: { profilePage: number, hashtagPostsPage: number, tradingPostsPage: number, liveFeedPage: number, topPage: number } = {
    profilePage: 0,
    hashtagPostsPage: 0,
    tradingPostsPage: 0,
    liveFeedPage: 0,
    topPage: 0
  }
  isClickBack: boolean = false
  isClickBackToProfile: boolean = false
  isRedirectFromStream: boolean = false

  //profile page info
  tab: activeTabType = activeTabType.myPosts
  pageSettingsProfile: ILoadPage = defaultLoadPage
  startTotalItems: number = 0

  numberOfNewPosts: number = 0
  feedPosts: ILiveFeed[] = []

  isFullScreen: boolean = false


  constructor() {
    makeAutoObservable(this)

    // Don't need in session storage
    makePersistable(this, {
      name: 'profilePage',
      properties: ['tab'],
      storage: window.sessionStorage
    })
  }

  //profile page action

  setSelectedProfilePageData(value: ISelectedProfilePageData) {
    this.selectedProfilePageData = value
  }


  setTab(value: activeTabType) {
    this.tab = value
  }


  setPageSettingsProfile(value: ILoadPage) {
    this.pageSettingsProfile = value
  }


  setStartTotalItems(value: number) {
    this.startTotalItems = value
  }


  setNumberOfNewPosts(value: number) {
    this.numberOfNewPosts = value
  }


  setIsFullScreen(value: boolean) {
    this.isFullScreen = value
  }


  setIsClickBack(value: boolean) {
    this.isClickBack = value
  }


  setIsClickBackToProfile(value: boolean) {
    this.isClickBackToProfile = value
  }

  setIsRedirectFromStream(value: boolean) {
    this.isRedirectFromStream = value
  }


  setFeedPosts(value: ILiveFeed[]) {
    this.feedPosts = value
  }


  setScrollPosition(key: 'profilePage' | 'hashtagPostsPage' | 'tradingPostsPage' | 'liveFeedPage' | 'topPage', value?: number) {
    if (value || value === 0) {
      this.scrollPosition[key] = value
    } else {
      this.scrollPosition[key] = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    }
  }
}

export default new PageActivities()
