import {
  getAuthencityObjectERC721AndUserWallet,
  getNetworkTypeFromChainId,
  getPostAsString,
  isAvailableBlockchainProviderToast,
} from 'utils/index'

import { toast } from 'App'

import { ISendPublication } from 'models'

import createPost from 'store/createPost'
import publicationStore from 'store/publication'
import signalRStore from 'store/signalR'
import transaction from 'store/transaction'

export const createPostInBlockchain = async (
  postData: ISendPublication[],
  onSend: (isError?: boolean) => void
) => {
  try {
    if (!isAvailableBlockchainProviderToast()) return
    const { AuthencityObjectERC721, userWallet, chainId } =
      await getAuthencityObjectERC721AndUserWallet()

    const NetworkType = chainId ? getNetworkTypeFromChainId(chainId) : undefined

    if (!AuthencityObjectERC721) {
      return toast({
        type: 'warning',
        message: 'Choose the Ethereum or Polygon network',
      })
    }

    if (NetworkType !== undefined) {
      const post = await createPost.createPost(
        postData,
        0,
        null,
        undefined,
        NetworkType,
        true
      )
      const tokenId = post.Id
      const tokenURI = `${process.env.REACT_APP_LINK_OBJECT_TOKEN_URI}${tokenId}` //-◄-Let new token --- TEST
      const tokenType = 'publication'
      const postAsStringWithID = await getPostAsString(postData, {
        id: tokenId,
      })

      AuthencityObjectERC721.mintWithTokenObjectData(
        userWallet, //to - the address of the wallet
        tokenId, //tokenId - Total objects sum + 1
        tokenURI.toString(), //tokenURI
        tokenType, //tokenType - unique name (Can be any)
        postAsStringWithID, //tokenData - publication text + file links in string
        '', //tokenKey
        tokenId //databaseKey
      )
        .then(async (res: any) => {
          if (res) {
            const post = await createPost.createPost(
              postData,
              tokenId,
              res.hash,
              tokenId,
              NetworkType as number
            )

            if (post) {
              publicationStore.setLastCreatedPublicationId(Number(post.Id))
              if (signalRStore.connection) {
                try {
                  await signalRStore.connection.invoke('SendMessage', post.Id)
                } catch (e) {
                  console.log(e)
                }
              }

              onSend()
              toast({
                type: 'success',
                message: 'Post has been created',
              })
            }

            transaction.setIsActive(false)
          } else {
            onSend(true)
            transaction.setIsActive(false)
            toast({
              type: 'warning',
              message: 'Failed to create publication',
            })
          }
        })
        .catch((e: any) => {
          console.log('e', e)
          onSend(true)
          transaction.setIsActive(false)
          const errorMessage = () => {
            if (e.code === 'ACTION_REJECTED' || e.code === 4001) {
              return `User denied transaction signature.`
            } else if (e.error) {
              return `Metamask error code: ${e.error?.code} ${e.error?.message}`
            } else {
              return e.message ? e.message : 'Some error'
            }
          }
          toast({
            type: 'warning',
            message: errorMessage(),
          })
        })
    }
  } catch (e: any) {
    console.log(e.message)

    onSend(true)
    toast({
      type: 'warning',
      message: 'Failed to create publication',
    })
  }
}
