import axios from 'axios'

export const cancelTokenSource = axios.CancelToken.source()
const profile = localStorage.getItem('profile')
let token = ''
if (profile) {
  try {
    const parsedProfile = JSON.parse(profile)
    if (parsedProfile && parsedProfile.token) {
      token = parsedProfile.token
    }
  } catch (e) {}
}

if (!token) {
  const profileSession = sessionStorage.getItem('profile')
  if (profileSession) {
    try {
      const parsedProfile = JSON.parse(profileSession)
      if (parsedProfile && parsedProfile.tokenSession) {
        token = parsedProfile.tokenSession
      }
    } catch (e) {}
  }
}

export const api = axios.create({
  validateStatus: function (status: number) {
    return Boolean(status)
  },
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    // Don't need with new version axios
    // accept: 'text/plain',
    // 'Content-Type': 'application/json-patch+json',
    Authorization: token,
  },
  cancelToken: cancelTokenSource.token,
})
