import { useCallback, useEffect, useRef, useState } from 'react'
import { useStateWithCallBack } from './useStateWithCallBack'
import socket from '../socket'
import ACTIONS from 'utils/socket/actions'
//@ts-ignore
import freeice from 'freeice'
import { useStore } from 'store'

export const LOCAL_VIDEO = 'LOCAL_VIDEO'

export const useWebRTC = (roomID: string, rooms: string[], preferences: string[]) => {
  const { videoChat, loading } = useStore()
  const [clients, updateClients] = useStateWithCallBack<string[]>([])
  const [isShowVideo, setIsShowVideo] = useState(false)

  const iceServersRef = useRef<[]>([])
  const peerConnections = useRef<{ [key: string]: RTCPeerConnection }>({})
  const localMediaStream = useRef<MediaStream | null>(null)
  const interlocutorMediaStream = useRef<MediaStream | null>(null)

  const peerMediaElements = useRef<{ [key: string]: HTMLVideoElement | null }>({ [LOCAL_VIDEO]: null })
  const localRoomIdRef = useRef<string | null>(null)

  const addNewClient = useCallback((newClient: string, cb: () => void) => {
    updateClients(list => {
      if (!list.includes(newClient)) {
        return [...list, newClient]
      }

      return list
    }, cb)
  }, [clients, updateClients])

  useEffect(() => {
    getIceServers()
  }, [])

  const getIceServers = async () => {
    const response = await fetch("https://webrtc_iceservers.metered.live/api/v1/turn/credentials?apiKey=496b256e4565b1b7c525c447020d29a2cd08");
    const iceServers = await response.json();
    iceServersRef.current = iceServers
  }

  const handleChangeRoom = async () => {
    try {
      const roomResponse = await videoChat.checkRoom(preferences.join())
      if (roomResponse.RoomId) {
        // const responseUpdateRoom = await videoChat.updateRoom(roomResponse.RoomId)
        // if (responseUpdateRoom.RoomId) {
        //   localRoomIdRef.current = responseUpdateRoom.RoomId
        //   videoChat.setActiveRoom(responseUpdateRoom.RoomId)
        //   videoChat.setActiveRoomData(responseUpdateRoom)
        //   socket.emit(ACTIONS.JOIN, { room: responseUpdateRoom.RoomId, roomInfo: responseUpdateRoom })
        // }
        localRoomIdRef.current = roomResponse.RoomId
        videoChat.setActiveRoom(roomResponse.RoomId)
        videoChat.setActiveRoomData(roomResponse)
        socket.emit(ACTIONS.JOIN, { room: roomResponse.RoomId, roomInfo: roomResponse })
      } else {
        const responseNewRoom = await videoChat.createRoom(roomID, preferences.join())
        if (responseNewRoom.RoomId) {
          localRoomIdRef.current = responseNewRoom.RoomId
          videoChat.setActiveRoom(responseNewRoom.RoomId)
          socket.emit(ACTIONS.JOIN, { room: responseNewRoom.RoomId, roomInfo: responseNewRoom })
        }
      }
    } catch (e) {
      console.log('handleChangeRoomError', e)
    }
  }

  const handleLeaveRoom = async () => {
    try {
      localRoomIdRef.current = ''
      videoChat.setActiveRoom('')
      socket.emit(ACTIONS.LEAVE)
    } catch (e) {
      console.log('handleLeaveRoomError', e)
    }
  }

  const leaveRoomBeforeunload = async () => {
    try {
      if (localRoomIdRef.current) {
        fetch(`${process.env.REACT_APP_BASE_URL}api/Room/leaveRoom?roomId=${String(localRoomIdRef.current)}`, {
          method: 'GET',
          keepalive: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        socket.emit(ACTIONS.LEAVE)
        // added the delay otherwise database operation will not work
        for (let i = 0; i < 500000000; i++) {}
        console.log('beforeunload')
      }
    } catch (e) {
      console.log('handleLeaveRoomBeforeunload', e)
    }
  }
  useEffect(() => {
    window.addEventListener('beforeunload', leaveRoomBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', leaveRoomBeforeunload)
    }
  }, [])

  useEffect(() => {
    if (isShowVideo) {
      roomID && handleChangeRoom()
    }
    return () => {
      localRoomIdRef.current && handleLeaveRoom()
    }
  }, [roomID])

  //Show video from own camera
  useEffect(() => {
    async function startCapture() {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: { ideal: 2, min: 1 },
          sampleRate: 48000,
          sampleSize: 16,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
        video: {
          width: 1280,
          height: 720,
          // height: 780,
          // width: 660,
          // height: 740,

        },
      })

      addNewClient(LOCAL_VIDEO, () => {
        const localVideoElement = peerMediaElements.current[LOCAL_VIDEO]

        if (localVideoElement) {
          localVideoElement.volume = 0
          localVideoElement.srcObject = localMediaStream.current
        }
      })
    }

    startCapture()
      .then(() => {
        setIsShowVideo(true)
        setTimeout(() => {
          loading.setLoading(false)
        }, 1000)
      })
      // .then(() => socket.emit(ACTIONS.JOIN, { room: roomID }))
      .catch(e => console.error('Error getting userMedia:', e))

    return () => {
      localMediaStream.current?.getTracks().forEach(track => track.stop())
      socket.emit(ACTIONS.LEAVE)
    }
  }, [])


  //Send your video to others and receive videos from others
  useEffect(() => {
    async function handleNewPeer({ peerID, createOffer, roomInfo }: { peerID: string, createOffer: boolean, roomInfo: any }) {
      console.log('handleNewPeer', 'peerID:', peerID, 'createOffer:', createOffer, 'roomInfo:', roomInfo)
      videoChat.setActiveRoomData(roomInfo)
      if (peerID in peerConnections.current) {
        return console.warn(`Already connected to peer ${peerID}`)
      }

      peerConnections.current[peerID] = new RTCPeerConnection({
        iceServers: iceServersRef.current,
        // iceServers: freeice(),
      })

      peerConnections.current[peerID].onicecandidate = event => {
        if (event.candidate) {
          socket.emit(ACTIONS.RELAY_ICE, {
            peerID,
            iceCandidate: event.candidate,
          })
        }
      }

      let tracksNumber = 0
      peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
        tracksNumber++

        interlocutorMediaStream.current = remoteStream

        if (tracksNumber === 2) { // video & audio tracks received
          tracksNumber = 0
          addNewClient(peerID, () => {
            if (peerMediaElements.current[peerID]) {
              (peerMediaElements.current[peerID] as HTMLVideoElement).srcObject = remoteStream

            } else {
              // FIX LONG RENDER IN CASE OF MANY CLIENTS
              let settled = false
              const interval = setInterval(() => {
                if (peerMediaElements.current[peerID]) {
                  (peerMediaElements.current[peerID] as HTMLVideoElement).srcObject = remoteStream
                  settled = true
                }

                if (settled) {
                  clearInterval(interval)
                }
              }, 1000)
            }
          })
        }
      }


      // Send your video
      localMediaStream.current?.getTracks().forEach(track => {
        peerConnections.current[peerID].addTrack(track, localMediaStream.current as MediaStream)
      })

      if (createOffer) {
        const offer = await peerConnections.current[peerID].createOffer()

        await peerConnections.current[peerID].setLocalDescription(offer)

        socket.emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: offer,
        })
      }
    }

    socket.on(ACTIONS.ADD_PEER, handleNewPeer)

    return () => {
      socket.off(ACTIONS.ADD_PEER)
    }
  }, [])


  useEffect(() => {
    const handleRemovePeer = ({ peerID }: { peerID: string }) => {
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].close()
      }

      delete peerConnections.current[peerID]
      delete peerMediaElements.current[peerID]

      updateClients(list => list.filter(client => client !== peerID))
    }

    socket.on(ACTIONS.REMOVE_PEER, handleRemovePeer)

    return () => {
      socket.off(ACTIONS.REMOVE_PEER)
    }
  }, [])

  useEffect(() => {
    async function setRemoteMedia({ peerID, sessionDescription: remoteDescription }: { peerID: string, sessionDescription: RTCSessionDescriptionInit }) {
      await peerConnections.current[peerID]?.setRemoteDescription(
        new RTCSessionDescription(remoteDescription),
      )

      if (remoteDescription.type === 'offer') {
        const answer = await peerConnections.current[peerID].createAnswer()

        await peerConnections.current[peerID].setLocalDescription(answer)
        socket.emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: answer,
        })
      }
    }

    socket.on(ACTIONS.SESSION_DESCRIPTION, setRemoteMedia)

    return () => {
      socket.off(ACTIONS.SESSION_DESCRIPTION)
    }
  }, [])

  useEffect(() => {
    socket.on(ACTIONS.ICE_CANDIDATE, ({ peerID, iceCandidate }: { peerID: string, iceCandidate: RTCIceCandidate }) => {
      peerConnections.current[peerID]?.addIceCandidate(
        new RTCIceCandidate(iceCandidate),
      )
    })

    return () => {
      socket.off(ACTIONS.ICE_CANDIDATE)
    }
  }, [])

  const provideMediaRef = useCallback((id: string, node: HTMLVideoElement | null) => {
    peerMediaElements.current[id] = node
  }, [])

  return { clients, provideMediaRef, peerMediaElements, localMediaStream, interlocutorMediaStream }
}
