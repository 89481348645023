import { observable, action, makeAutoObservable } from 'mobx'

interface IStatusesInfo {
  NamesClaimed: number,
  Posts: number,
  ClaimedAuthSupply: number,
  LiveAuthSupply: number
}

interface IUpdateStatusesInfo {
  NamesClaimed?: number,
  Posts?: number,
  ClaimedAuthSupply?: number,
  LiveAuthSupply?: number
}

class StatusesStore {
  statusesInfo: IStatusesInfo = {NamesClaimed: 0, Posts: 0, ClaimedAuthSupply: 0,LiveAuthSupply: 0}


  constructor() {
    makeAutoObservable(this)
  }


  setStatusesInfo(updateStatusesInfo: IUpdateStatusesInfo) {
    this.statusesInfo = { ...this.statusesInfo, ...updateStatusesInfo }
  }
}

export default new StatusesStore()
