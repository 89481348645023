import React, { FC, useEffect } from 'react'

import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import { TransitionProps } from '@mui/material/transitions'

import authLogo from '../../sources/images/authTokenNormal.svg'

import ButtonNew from '../ButtonNew'

import styles from './styles.module.scss'

interface IProps {
  bannerKey: string
  text: string
  closeButton: {
    text: string
    onClick?: () => void
  }
  actionButton: {
    text: React.ReactNode
    onClick: () => void
  }
  delay?: number
}

export const BottomBanner: FC<IProps> = ({
  bannerKey,
  text,
  closeButton,
  actionButton,
  delay,
}) => {
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    const showBanner = () => {
      setOpen(true)
    }

    if (!localStorage.getItem(bannerKey)) {
      if (!delay) {
        showBanner()
      } else {
        setTimeout(showBanner, delay)
      }
    }
  }, [bannerKey, delay])

  const hideBanner = () => {
    setOpen(false)
  }

  const handleClose = () => {
    localStorage.setItem(bannerKey, 'true')
    setOpen(false)
    closeButton?.onClick?.()
  }

  const handleClick = () => {
    handleClose()
    actionButton?.onClick?.()
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      classes={{ root: styles.snackbar }}
      ContentProps={{
        classes: {
          root: styles.contentRoot,
          action: styles.action,
          message: styles.message,
        },
      }}
      TransitionComponent={slideTransition}
      transitionDuration={{
        enter: 500,
        appear: 250,
        exit: 250,
      }}
      autoHideDuration={15000}
      onClose={hideBanner}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      message={
        <>
          <img src={authLogo} className={styles.logo} />
          <p>{text}</p>
        </>
      }
      action={
        <div className={styles.btnBox}>
          <ButtonNew
            className={styles.button}
            onClick={handleClose}
            btnText={closeButton?.text}
            btnStyle={{
              background: '#EDF0F9',
            }}
            btnStyleType={'style_6'}
            btnTextStyle={{ color: '#2E2B63' }}
          />

          <ButtonNew
            className={styles.button}
            onClick={handleClick}
            btnText={actionButton?.text}
            btnStyle={{}}
            btnStyleType={'style_6'}
            btnTextStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.25rem',
            }}
          />
        </div>
      }
    />
  )
}

BottomBanner.displayName = 'BottomBanner'

const slideTransition = (props: TransitionProps) => {
  return <Slide {...(props as any)} direction="up" />
}
