import { useEffect, useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import { useWeb3Modal } from '@web3modal/react'
// @ts-ignore
import { appleAuthHelpers } from 'react-apple-signin-auth'
import { useNavigate } from 'react-router-dom'

import { toHex } from 'viem'
import { useAccount, useDisconnect } from 'wagmi'

import { toast } from '../../App'

import { useStore } from '../../store'

import { LoginProvider, TwitterEventType } from '../const'
import { invitation_change_nickname, restore_account } from '../routes'

const { ethereum } = window

interface IProps {
  type?: 'default' | 'logIn' | 'connect' | 'loginWidget'
  connectedWallet?: string
  closeLoginModal?: () => void
  setIsOpenedRegisterModal?: (isOpened: boolean) => void
}

export const useAuth = ({
  type = 'default',
  closeLoginModal,
  connectedWallet,
  setIsOpenedRegisterModal,
}: IProps) => {
  const { loading, login: loginStore, profile: profileStore } = useStore()

  const navigate = useNavigate()

  const [isClickWalletConnectButton, setClickWalletConnectButton] =
    useState(false)

  const { address, connector, isConnected } = useAccount()
  const { open: openWeb3Modal, close: closeWeb3Modal } = useWeb3Modal()
  const { disconnect: disconnectWalletConnect } = useDisconnect()

  const onLoginSuccess = (response: any) => {
    const message =
      type === 'connect'
        ? 'You have successfully connected wallet'
        : 'You have logged in successfully'
    if (response) {
      closeLoginModal?.()
      const { Item1: user } = response

      if (
        user?.AbleToChangeNickname &&
        sessionStorage.getItem('newRegisteredProfile')
      ) {
        navigate(invitation_change_nickname())
      } else if (user?.DeletionMark) {
        navigate(restore_account())
      } else {
        if (type !== 'loginWidget') {
          navigate(`/user/${user.NickName}`)
        }
      }

      toast({
        type: 'success',
        message,
      })
    }
  }

  // ------------------------------------------------------------------------------------------------------------------

  // GOOGLE ---------------------------------------⬇︎⬇︎⬇︎
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async codeResponse => {
      try {
        loading.setLoading(true)
        const response = await loginStore.loginGoogle(codeResponse.access_token)
        onLoginSuccess(response)
        loading.setLoading(false)
      } catch (e: any) {
        loading.setLoading(false)

        if (type === 'loginWidget') {
          await registerWithGoogle(codeResponse.access_token)
        } else {
          toast({
            type: 'warning',
            message: e.message || 'Log In Failed',
          })
          console.log('e', e)
        }
      }
    },
    onError: error => {
      toast({
        type: 'warning',
        message: 'Log In Failed',
      })
      console.log('Log In Failed:', error)
    },
  })

  const registerWithGoogle = async (access_token: string) => {
    try {
      await loginStore.signUpGoogle(access_token)
    } catch (e: any) {
      console.log('register with google in widget', e)
      toast({
        type: 'warning',
        message: e?.message,
      })
    }
  }

  // GOOGLE ---------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // APPLE ---------------------------------------⬇︎⬇︎⬇︎
  const handleAppleLogin = async () => {
    try {
      appleAuthHelpers.signIn({
        authOptions: {
          clientId: 'io.authencity.app.login.identifier',
          redirectURI: window.location.origin,
          scope: 'email name',
          state: 'state',
          nonce: 'nonce',
          usePopup: true,
        },
        onSuccess: async (appleResponse: any) => {
          try {
            loading.setLoading(true)
            const response = await loginStore.loginApple(
              appleResponse.authorization.id_token
            )
            onLoginSuccess(response)
            loading.setLoading(false)
          } catch (e: any) {
            loading.setLoading(false)

            if (type === 'loginWidget') {
              await registerWithApple(appleResponse.authorization.id_token)
            } else {
              toast({
                type: 'warning',
                message: e.message || 'Log In Failed',
              })
              console.log('e', e)
            }
          }
        },
        onError: (e: any) => {
          console.log(e)
          if (e.error === 'popup_closed_by_user') return
          toast({
            type: 'warning',
            message: 'Log In Failed',
          })
        },
      })
    } catch (e) {}
  }

  const registerWithApple = async (id_token: string) => {
    try {
      await loginStore.signUpApple(id_token)
    } catch (e: any) {
      console.log('register with apple in widget', e)
      toast({
        type: 'warning',
        message: e?.message,
      })
    }
  }
  // APPLE ---------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // TWITTER ---------------------------------------⬇︎⬇︎⬇︎
  const handleTwitterLogin = async () => {
    try {
      const twitterUrl = await profileStore.requestToken(TwitterEventType.Login)
      sessionStorage.setItem('log_in_twitter', String(new Date()))
      // window.location.href = twitterUrl
      const win = window.open(twitterUrl, '_self')
      if (win) {
        win.focus()
      }
    } catch (e) {
      toast({
        type: 'warning',
        message: 'Log In Failed',
      })
    }
  }
  // TWITTER ---------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // 2 type fo connect
  // CONNECT WALLET (Metamask & WalletConnect)------------------------------------⬇︎⬇︎⬇︎
  const connectWallet = async (
    wallet: string,
    provider: any,
    typeProvider: LoginProvider
  ) => {
    try {
      const isWalletRegistered =
        await loginStore.checkWalletIsRegisteredInSystem(wallet)

      if (isWalletRegistered && !connectedWallet) {
        return toast({
          type: 'warning',
          message:
            'This wallet is already connected with another profile in our system. Please, choose another wallet!',
        })
      } else if (
        isWalletRegistered &&
        connectedWallet &&
        connectedWallet !== wallet
      ) {
        return toast({
          type: 'warning',
          message: 'Select the wallet that is linked to the profile!',
        })
      }

      const nonce = connectedWallet
        ? await loginStore.getNonce(wallet)
        : await loginStore.connectWallet(wallet, typeProvider)

      const params = [toHex(nonce.toString()), wallet]

      toast({
        type: 'success',
        message: 'Sign the message in Metamask',
      })
      provider
        .request({ method: 'personal_sign', params })
        .then(async (hash: any) => {
          // Don't use 2fa if type === 'connect'
          const response = await loginStore.loginWallet(
            wallet,
            hash,
            typeProvider,
            true,
            type === 'loginWidget' ? 'widget' : 'default'
          )

          onLoginSuccess(response)
        })
        .catch((error: any) => {
          console.log('error', error)
          let message = error.message || ''
          if (message.match(/User rejected/i)) {
            message = 'User rejected the request.'
          }
          toast({
            type: 'warning',
            message: message || 'Connect Failed',
          })
        })
    } catch (e: any) {
      toast({
        type: 'warning',
        message: e.message || 'Connect Failed',
      })
    }
  }
  // CONNECT WALLET (Metamask & WalletConnect)------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // COMMON WALLET LOGIN (Metamask & WalletConnect)------------------------------------⬇︎⬇︎⬇︎
  const loginWithWallet = async (
    userWallet: string,
    provider: any,
    typeProvider: LoginProvider
  ) => {
    try {
      const isWalletRegistered =
        await loginStore.checkWalletIsRegisteredInSystem(userWallet)
      console.log('isWalletRegistered', isWalletRegistered)

      if (isWalletRegistered) {
        const nonce = await loginStore.getNonce(userWallet)
        console.log('nonce', nonce)

        const params = [toHex(nonce.toString()), userWallet]

        toast({
          type: 'success',
          message: 'Sign the message in Metamask',
        })
        provider
          .request({ method: 'personal_sign', params })
          .then(async (hash: any) => {
            console.log('hash', hash)
            const response = await loginStore.loginWallet(
              userWallet,
              hash,
              typeProvider,
              undefined,
              type === 'loginWidget' ? 'widget' : 'default'
            )

            response && onLoginSuccess(response)
          })
          .catch((error: any) => {
            console.log('error', error)
            let message = error.message || ''
            if (message.match(/User rejected/i)) {
              message = 'User rejected the request.'
            }
            toast({
              type: 'warning',
              message: message || 'Log In Failed',
            })
          })
      } else if (type === 'loginWidget') {
        setIsOpenedRegisterModal?.(true)
      } else {
        toast({
          type: 'warning',
          message:
            'This wallet is not associated with any profile in our system. Please, sign up!',
        })
      }
    } catch (e: any) {
      toast({
        type: 'warning',
        message: e.message || 'Log In Failed',
      })
    }
  }

  const checkRegistrationWallet = async (userWallet: string) => {
    try {
      const isWalletRegistered =
        await loginStore.checkWalletIsRegisteredInSystem(userWallet)
      if (isWalletRegistered) {
        toast({
          type: 'warning',
          message: `User with this wallet ${
            userWallet.slice(0, 4) + '...' + userWallet.slice(-4)
          } already exist`,
        })
      }

      return isWalletRegistered
    } catch (e) {
      console.log('e', e)
    }
  }

  // COMMON WALLET LOGIN (Metamask & WalletConnect)------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // METAMASK ---------------------------------------⬇︎⬇︎⬇︎
  const handleMetamaskLogin = async () => {
    if (ethereum) {
      try {
        await ethereum.request({ method: 'eth_requestAccounts' })
        const userWallets = await ethereum.request({ method: 'eth_accounts' })
        const userWallet = userWallets[0]

        if (type === 'connect') {
          await connectWallet(userWallet, ethereum, LoginProvider.Metamask)
        } else {
          await loginWithWallet(userWallet, ethereum, LoginProvider.Metamask)
        }
      } catch (error: any) {
        console.log('error', error)
        let message = error.message || ''
        if (message.match(/Already processing/i)) {
          message =
            'The MetaMask extension is already open and awaiting to be unlocked'
        }
        toast({
          type: 'warning',
          message: message || 'Log In Failed',
        })
      }
    } else {
      toast({
        type: 'warning',
        message:
          'This feature is not available because you are not using MetaMask',
      })
    }
  }
  // METAMASK ---------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // WALLET CONNECT ---------------------------------------⬇︎⬇︎⬇︎
  useEffect(() => {
    if (isConnected && ['login', 'connect', 'loginWidget'].includes(type)) {
      isClickWalletConnectButton && onWalletConnectConnected()
    }
  }, [isConnected, type])

  const onWalletConnectConnected = async () => {
    if (connector && isConnected && address) {
      const provider = await connector.getProvider()

      if (type === 'connect') {
        await connectWallet(address, provider, LoginProvider.WalletConnect)
      } else {
        await loginWithWallet(address, provider, LoginProvider.WalletConnect)
      }
    }
  }

  const handleWalletConnectLogin = async () => {
    if (isConnected) {
      await onWalletConnectConnected()
    } else {
      setClickWalletConnectButton(true)
      await openWeb3Modal()
    }
  }
  // WALLET CONNECT ---------------------------------------⬆︎⬆︎⬆︎

  // ------------------------------------------------------------------------------------------------------------------

  // LOGOUT ---------------------------------------⬇︎⬇︎⬇︎

  const logOut = (redirect: boolean) => {
    profileStore.clearSessionProfileData()
    profileStore.clearSessionTokenData()
    disconnectWalletConnect()
    localStorage.removeItem('test_iOS_app')

    if (redirect) {
      navigate('/')
    }

    toast({
      type: 'success',
      message: 'You have logged off successfully',
    })
  }

  const logoutRedirect = () => {
    logOut(true)
  }

  const logoutWithoutRedirect = () => {
    logOut(false)
  }

  // LOGOUT ---------------------------------------⬆︎⬆︎⬆︎

  return {
    handleGoogleLogin,
    handleAppleLogin,
    handleTwitterLogin,
    handleMetamaskLogin,
    handleWalletConnectLogin,
    checkRegistrationWallet,
    logOut,
    logoutRedirect,
    logoutWithoutRedirect,
  }
}
