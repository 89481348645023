import React, { useImperativeHandle, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

import styles from './styles.module.scss'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export type TToastOpen = {
  type: 'success' | 'info' | 'warning' | 'error' | undefined
  message: string
}

export interface IModalHandles {
  open(data: TToastOpen): void
}

const Component: React.ForwardRefRenderFunction<IModalHandles> = (
  props,
  ref
) => {
  const [enable, setEnable] = useState(false)
  const [value, SetValue] = useState<TToastOpen>({
    type: undefined,
    message: '',
  })
  const open = (data: TToastOpen) => {
    setEnable(true)
    SetValue(data)
  }
  const onClose = () => {
    setEnable(false)
  }

  useImperativeHandle(ref, () => ({
    open,
  }))

  return (
    <Snackbar
      autoHideDuration={6000}
      classes={{
        root: styles.snackMy,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={enable}
      onClose={() => onClose()}
    >
      <Alert onClose={() => onClose()} severity={value.type}>
        {value.message}
      </Alert>
    </Snackbar>
  )
}

export default Component
