import { makeAutoObservable } from 'mobx'
import { api } from 'utils'

class MainStore {
  isOpenInstallMetaMaskModal: boolean = false
  isPWA: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setOpenInstallMetaMaskModal() {
    this.isOpenInstallMetaMaskModal = !this.isOpenInstallMetaMaskModal
  }

  setIsPWA(value: boolean) {
    this.isPWA = value
  }

  async search(searchText: string) {
    searchText = searchText.split('#').join('%23')
    const response = await api.get(
      `api/Search/search?searchText=${searchText}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }


  async getMainPageInfo() {
    const response = await api.get(
      `/api/AUTH/GetMainPageInfo`
    )
    if (response.status !== 200)
      throw new Error(response.data && response.data.Description ? response.data.Description : 'Some error')

    return response.data
  }

  setSessionStorage(name: string, value: string) {
    sessionStorage.setItem(name, value)
  }


  getSessionStorage(name: string) {
    return sessionStorage.getItem(name)
  }


  setLocalStorage(name: string, value: string) {
    localStorage.setItem(name, value)
  }


  getLocalStorage(name: string) {
    return localStorage.getItem(name)
  }
}

export default new MainStore()
