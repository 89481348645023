import { action, makeAutoObservable } from 'mobx'
import { api } from 'utils/config'
import type { ITweet } from '../models'

class TweetsStore {
  constructor() {
    makeAutoObservable(this)
  }

  
  async getTweets(TwitterId: string, pagination?: string) {
    const response = await api.get(
      `/api/Twitter`,
      {
        params: {
          twitterUserId: TwitterId,
          expansions: 'author_id,entities.mentions.username,attachments.media_keys',
          tweet_Fields: 'public_metrics,entities,author_id,created_at,text',
          user_fields: 'created_at,name,profile_image_url,username',
          max_results: 10,
          media_fields: 'media_key,preview_image_url,type,url',
          pagination_token: pagination,
        },
      },
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  async getTweetVideo(TweetId: string) {
    const response = await api.get(
      `/api/Twitter/getVideo`,
      {
        params: {
          id: TweetId,
        },
      },
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  getTweetText(tweet: ITweet): { text: string, url: string } {
    if (tweet.entities) {
      if (tweet.entities.mentions && tweet.entities.urls) {
        return { text: tweet.text.slice(tweet.entities.mentions[0].end + 1, tweet.entities.urls[0].start), url: '' }
      } else if (tweet.entities.mentions) {
        return { text: tweet.text.slice(tweet.entities.mentions[0].end + 1), url: '' }
      } else {
        // if (tweet.entities.urls[0].title || tweet.entities.urls[0].description || tweet.entities.urls[0].expanded_url) {
        const mainText = tweet.text.slice(0, tweet.entities.urls[0].start)
        const title = tweet.entities.urls[0].title ? `${tweet.entities.urls[0].title}. ` : ''
        const description = tweet.entities.urls[0].description ? `${tweet.entities.urls[0].description} ` : ''
        const expanded_url = tweet.entities.urls[0].expanded_url ? tweet.entities.urls[0].expanded_url : ''
        return { text: `${mainText}${title}${description}`, url: expanded_url }
        // } else {
        // 	tweet.text.slice(0, tweet.entities.urls[0].start)
        // }
      }
    } else {
      return { text: tweet.text, url: '' }
    }
  }
}

export default new TweetsStore()
