import { action, makeAutoObservable } from 'mobx'
import {api} from 'utils/config'

class InviteStore {
	constructor() {
		makeAutoObservable(this)
	}

	
	async getInviteData() {
		const response = await api.get(`api/Account/GetInviteData`)

		if (response.status !== 200)
			throw new Error(
				response.data && response.data.Description
					? response.data.Description
					: 'Some error'
			)
		return response.data
	}

	
	async sendInviteMessage(emailArray: string[]) {
		const response = await api.post(
			`api/Account/SendInviteMessage`,
			emailArray,
		)
		if (response.status !== 200)
			throw new Error(
				response.data && response.data.Description
					? response.data.Description
					: 'Some error'
			)
		return response.data
	}
}

export default new InviteStore()
