import React, { CSSProperties } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  btnStyleType:
    | 'style_1'
    | 'style_2'
    | 'style_3'
    | 'style_4'
    | 'style_5'
    | 'style_6'
    | 'style_7'
    | 'style_8'
    | 'style_9'
    | 'style_10'
    | 'style_11'
    | 'style_12'
    | 'style_13'
  btnType?: 'submit' | 'button'
  btnStyle: CSSProperties
  btnText?: React.ReactNode | JSX.Element
  btnTextStyle?: CSSProperties
  imgSrc?: string
  imgAlt?: string
  imgStyle?: CSSProperties
  isSvgImage?: boolean
  imgSvgStyle?: CSSProperties
  isDisabled?: boolean
  onClick?: () => void
  onClickWithEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void
  props?: any
  className?: string
}

//Resize
const ButtonNew: React.FC<IProps> = ({
  btnStyleType,
  btnType = 'button',
  btnStyle,
  btnText,
  btnTextStyle,
  imgSrc,
  imgAlt = '',
  imgStyle,
  isSvgImage,
  imgSvgStyle,
  isDisabled,
  onClick,
  onClickWithEvent,
  props,
  className,
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick && onClick()
    onClickWithEvent && onClickWithEvent(event)
  }

  return (
    <button
      {...props}
      type={btnType}
      style={btnStyle}
      disabled={isDisabled}
      onClick={handleOnClick}
      className={cn({
        [styles.btn]: true,
        [styles.disabled]: isDisabled,
        [styles.btnStyle_1]: btnStyleType === 'style_1',
        [styles.btnStyle_2]: btnStyleType === 'style_2',
        [styles.btnStyle_3]: btnStyleType === 'style_3',
        [styles.btnStyle_4]: btnStyleType === 'style_4',
        [styles.btnStyle_5]: btnStyleType === 'style_5',
        [styles.btnStyle_6]: btnStyleType === 'style_6',
        [styles.btnStyle_7]: btnStyleType === 'style_7',
        [styles.btnStyle_8]: btnStyleType === 'style_8', //bordered
        [styles.btnStyle_9]: btnStyleType === 'style_9', //blue hover
        [styles.btnStyle_10]: btnStyleType === 'style_10', //inverted style_6
        [styles.btnStyle_11]: btnStyleType === 'style_11', //style_10 with black text
        [styles.btnStyle_12]: btnStyleType === 'style_12', //style_11 with green hover
        [styles.btnStyle_13]: btnStyleType === 'style_13', //inverted style_12
        [className as string]: !!className,
      })}
    >
      {imgSrc ? (
        <img
          style={imgStyle}
          src={imgSrc}
          alt={imgAlt}
          className={styles.imgIcon}
        />
      ) : null}
      {isSvgImage ? (
        <div className={styles.imgSvgIcon} style={imgSvgStyle} />
      ) : null}
      {btnText ? (
        <div
          style={btnTextStyle}
          className={cn({
            [styles.btnText]: true,
          })}
        >
          {btnText}
        </div>
      ) : null}
    </button>
  )
}

export default ButtonNew
