import e from 'express'
import cloneDeep from 'lodash/cloneDeep'
import { makeAutoObservable } from 'mobx'
import { storage } from 'three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements'
import { number } from 'yup'

import { api, defaultLoadPage } from '../utils'

import { toast } from '../App'

import type { ILoadPage, IPublication } from '../models'

import { makePersistable } from 'mobx-persist-store'

class Widget {
  apiKey: string = ''
  key: string = window.location.href.replace(/https?:\/\//g, '')
  name: string = window.location.host
  pathname: string = '' // this is for restoring url
  publicationsCount: number = 0

  publications: IPublication[] = []

  scrollPositionPublications: number = 0
  scrollPositionPublicationsWindow: number = 0

  pageSettingsPublications: ILoadPage = defaultLoadPage
  startTotalItemsPublications: number = 0
  totalCommentsCount: number = 0

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'widget',
      properties: ['apiKey', 'key', 'name', 'pathname'],
      storage: window.sessionStorage,
    })
  }

  // Widget settings

  setWidgetApiKey(key: any): void {
    this.apiKey = String(key)
  }

  setPublicationsCount(count: any) {
    if (+count >= 0) {
      this.publicationsCount = +count
    } else {
      this.publicationsCount = 5
    }
  }

  getWidgetAuthHeaders() {
    return {
      'API-KEY': this.apiKey,
    }
  }

  getWidgetParams() {
    return {
      key: this.key,
      name: this.name,
    }
  }

  onCreatePost(): void {
    this.totalCommentsCount++
  }

  setWidgetPathname(pathname: string): void {
    this.pathname = pathname
  }

  reset(): void {
    this.apiKey = ''
    this.pathname = ''
    this.publicationsCount = 0

    this.publications = []

    this.scrollPositionPublications = 0
    this.scrollPositionPublicationsWindow = 0

    this.pageSettingsPublications = defaultLoadPage
    this.startTotalItemsPublications = 0
    this.totalCommentsCount = 0

    if (sessionStorage.getItem('widget')) {
      sessionStorage.removeItem('widget')
    }
  }

  // Start Settings

  async getApiKey(name: string, website: string): Promise<string> {
    const response = await api.get(`/api/Widget/getApiKey`, {
      params: {
        name,
        website,
      },
      headers: this.getWidgetAuthHeaders(),
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // End Settings

  // Start Publications

  setPublications(value: IPublication[]) {
    this.publications = value
  }

  setScrollPositionPublications(value: number) {
    this.scrollPositionPublications = value
  }

  setScrollPositionPublicationsWindow(value: number) {
    this.scrollPositionPublicationsWindow = value
  }

  setPageSettingsPublications(value: ILoadPage) {
    this.pageSettingsPublications = value
    this.totalCommentsCount = value.TotalItems
  }

  setStartTotalItemsPublications(value: number) {
    this.startTotalItemsPublications = value
  }

  async getPublications(page: ILoadPage) {
    const response = await api.get(`/api/Widget/getAllPublicationsWithFilter`, {
      params: {
        pageNumber: page.PageNumber,
        pageSize: page.PageSize,
        ...this.getWidgetParams(),
      },
      headers: this.getWidgetAuthHeaders(),
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async deletePublicationFromStore(id: number) {
    this.publications = this.publications?.filter(e => e?.Id !== id)
    this.publicationsCount--
  }

  // for Widget component

  async getTopPublication(
    onStartLoading: () => void,
    onEndLoading: () => void
  ) {
    try {
      if (this.publicationsCount === 0) {
        return
      }

      onStartLoading()

      const response = await this.getPublications({
        ...this.pageSettingsPublications,
        PageSize: this.publicationsCount,
      })
      const { ListItems, TotalItems, PageNumber, PageSize, TotalPages } =
        response
      this.setPublications(ListItems)
      this.setStartTotalItemsPublications(TotalItems)
      this.setPageSettingsPublications({
        PageNumber,
        PageSize,
        TotalItems,
        TotalPages,
      })

      onEndLoading()
    } catch (e: any) {
      console.log(e.message)
      onEndLoading()
      toast({
        type: 'error',
        message: 'Error when retrieving the information. Please try again.',
      })
    }
  }

  setDefaultPageSettingsPublications() {
    this.setStartTotalItemsPublications(0)
    this.setPageSettingsPublications(defaultLoadPage)
    this.setPublications([])
  }

  async getPublication(
    isLoading: boolean,
    onStartLoading: () => void,
    onEndLoading: () => void,
    isNewProfile: boolean,
    isRefresh: boolean,
    paramPage: ILoadPage,
    total?: number
  ) {
    if (isLoading) return

    try {
      const newParamPage = paramPage ? paramPage : this.pageSettingsPublications

      onStartLoading()
      let newList =
        isNewProfile || isRefresh ? [] : cloneDeep(this.publications)
      const response = await this.getPublications(newParamPage)

      if (response?.ListItems) {
        const { ListItems, TotalItems, PageNumber, PageSize, TotalPages } =
          response
        newList = newList.concat(ListItems)
        this.setPageSettingsPublications({
          PageNumber,
          PageSize,
          TotalItems,
          TotalPages,
        })
        this.setPublications(newList)
        this.startTotalItemsPublications === 0 &&
          this.setStartTotalItemsPublications(TotalItems)
      }

      onEndLoading()
    } catch (e: any) {
      console.log(e.message)
      onEndLoading()
      toast({
        type: 'error',
        message: 'Error when retrieving the information. Please try again.',
      })
    }
  }

  // End Publications
}

export default new Widget()
