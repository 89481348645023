import { action, makeAutoObservable, observable } from 'mobx'
import type { ILoadPage, IPublication, ITopHashtagFull, IUser } from '../models'
import {
  api,
  defaultLoadPage,
  TabTypeTop,
  TabTypeTopPublications,
} from '../utils'

class TopStore {
  tabTypeTop: TabTypeTop = TabTypeTop.topPosts

  publications: IPublication[] = []
  profiles: IUser[] = []
  hashtags: ITopHashtagFull[] = []

  scrollPositionPublications: number = 0
  scrollPositionPublicationsWindow: number = 0
  scrollPositionProfiles: number = 0
  scrollPositionProfilesWindow: number = 0
  scrollPositionHashtags: number = 0
  scrollPositionHashtagsWindow: number = 0

  pageSettingsPublications: ILoadPage = defaultLoadPage
  startTotalItemsPublications: number = 0
  tabTypePublications: TabTypeTopPublications = TabTypeTopPublications.like

  pageSettingsProfiles: ILoadPage = defaultLoadPage
  startTotalItemsProfiles: number = 0

  pageSettingsHashtags: ILoadPage = defaultLoadPage
  startTotalItemsHashtags: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setTabTypeTop(value: TabTypeTop) {
    this.tabTypeTop = value
  }

  //Publications

  setPublications(value: IPublication[]) {
    this.publications = value
  }

  setScrollPositionPublications(value: number) {
    this.scrollPositionPublications = value
  }

  setScrollPositionPublicationsWindow(value: number) {
    this.scrollPositionPublicationsWindow = value
  }

  setPageSettingsPublications(value: ILoadPage) {
    this.pageSettingsPublications = value
  }

  setStartTotalItemsPublications(value: number) {
    this.startTotalItemsPublications = value
  }

  setTabTypePublications(value: TabTypeTopPublications) {
    this.tabTypePublications = value
  }

  //Profiles

  setProfiles(value: IUser[]) {
    this.profiles = value
  }

  setScrollPositionProfiles(value: number) {
    this.scrollPositionProfiles = value
  }

  setScrollPositionProfilesWindow(value: number) {
    this.scrollPositionProfilesWindow = value
  }

  setPageSettingsProfiles(value: ILoadPage) {
    this.pageSettingsProfiles = value
  }

  setStartTotalItemsProfiles(value: number) {
    this.startTotalItemsProfiles = value
  }

  //Hashtags

  setHashtags(value: ITopHashtagFull[]) {
    this.hashtags = value
  }

  setScrollPositionHashtags(value: number) {
    this.scrollPositionHashtags = value
  }

  setScrollPositionHashtagsWindow(value: number) {
    this.scrollPositionHashtagsWindow = value
  }

  setPageSettingsHashtags(value: ILoadPage) {
    this.pageSettingsHashtags = value
  }

  setStartTotalItemsHashtags(value: number) {
    this.startTotalItemsHashtags = value
  }

  async getTopPublications(pageNumber: number, sorting: 0 | 1 | 2) {
    const response = await api.get(`api/Publication/getTopPublicationFeed`, {
      params: {
        pageSize: 10,
        pageNumber,
        sorting,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getTopProfiles(pageNumber: number, sorting: 0 | 1 | 2 | 3) {
    const response = await api.get(`api/Account/getTopProfilesFeed`, {
      params: {
        pageSize: 30,
        pageNumber,
        sorting,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getTopHashtags(pageNumber: number) {
    const response = await api.get(`api/Publication/getHashTagsFeed`, {
      params: {
        pageSize: 30,
        pageNumber,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }
}

export default new TopStore()
