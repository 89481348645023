import { action, makeAutoObservable } from 'mobx'
import { api, apiError } from '../utils'
import LoginStore from 'store/login'

class TwoFactor {
  constructor() {
    makeAutoObservable(this)
  }

  async payForTwoFactor() {
    const response = await api.get(`api/TwoFactor/payForTwoFactor`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async addPaymentHashForTwoFactor(paymentHash: string) {
    const response = await api.get(`api/TwoFactor/addPaymentHashForTwoFactor?paymentHash=${paymentHash}`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async addPhoneNumberForTwoFactor(phoneNumber: string) {
    const response = await api.put(`api/TwoFactor/addPhoneNumberForTwoFactor?phoneNumber=%2B${phoneNumber}`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async sendMessageToUserForTwoFactor() {
    const response = await api.get(`api/TwoFactor/sendMessageToUserForTwoFactor`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async sendMessageToUserForTwoFactorAnonymous(fACode: string) {
    const response = await api.get(`api/TwoFactor/sendMessageToUserForTwoFactorAnonymous`, {
      params: {
        fACode
      }
    })

    if (response.status !== 200) apiError(response)
    return response.data
  }


  async confirmCodeFromMessage(code: string) {
    const response = await api.put(`api/TwoFactor/confirmCodeFromMessage?code=${code}`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  // Return token and user info
  async confirmCodeFromMessageAnonymous(fACode: string, code: string) {
    const response = await api.put(`api/TwoFactor/confirmCodeFromMessageAnonymous`, null, {
      params: {
        fACode,
        code
      }
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async verifyAuthenticatorCode(code: string) {
    const response = await api.get(`api/TwoFactor/verifyAuthenticatorCode?code=${code}`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  // Return token and user info
  async verifyAuthenticatorCodeAnonymous(fACode: string, code: string) {
    const response = await api.get(`api/TwoFactor/verifyAuthenticatorCodeAnonymous`, {
      params: {
        fACode,
        code
      }
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async disableTwoFactorSMS() {
    const response = await api.get(`api/TwoFactor/disableTwoFactorSMS`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async disableTwoFactorAuthenticator() {
    const response = await api.get(`api/TwoFactor/disableTwoFactorAuthenticator`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async getBackupCode() {
    const response = await api.get(`api/TwoFactor/getBackupCode`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async enterBackupCode(fACode: string, backupCode: string) {
    const response = await api.get(`api/TwoFactor/enterBackupCode`, {
      params: {
        fACode,
        backupCode
      }
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async getAuthenticatorInfo() {
    const response = await api.get(`api/TwoFactor/getAuthenticatorInfo`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }


  async checkTransactionStatus() {
    const response = await api.get(`api/TwoFactor/checkTransactionStatus`)

    if (response.status !== 200) {
      throw new Error(
        response.data && (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

}

export default new TwoFactor()
