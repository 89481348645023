import React from 'react'
import { Navigate, Route, RouteProps, useNavigate } from 'react-router-dom'

import { useStore } from 'store'
import { main_link } from 'utils'
import {toast} from 'App';
import { observer } from 'mobx-react'

interface IProps {
  message?: string
  redirectTo?: string | object
  children: React.ReactElement
}

export const PrivateRoute: React.FC<IProps> = observer(({message, redirectTo, children}) => {
  let allow = false
  const { profile } = useStore()
  // here private logic ...
  if (profile.isAuthorizedProfile()) {
    allow = true
  }

  // show a notification
  if (!allow) {
    console.error(
      message ? message : `You have not access to this page`
    )
    toast({
      type: 'warning',
      message: `You don't have access to this page. Please connect and select profile.`,
    })
  }

  return allow ?  children : <Navigate to={main_link()} replace />
})

export default PrivateRoute
