import React from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { Web3Button, Web3Modal } from '@web3modal/react'
import { Provider } from 'mobx-react'
import { createRoot } from 'react-dom/client'

import { WagmiConfig } from 'wagmi'

import { getEthereumClient, getWagmiConfig } from 'utils'

import App from './App'

import store from './store'

import 'sources/styles/styles.scss'
import 'sources/styles/theme.scss'

import reportWebVitals from './reportWebVitals'

import './index.scss'

const wagmiConfig = getWagmiConfig()
const ethereumClient = getEthereumClient()

console.log('eth c', ethereumClient)

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? ''
const root = createRoot(document.getElementById('root') as Element)

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
    {/*<React.StrictMode>*/}
    <Provider {...store}>
      <WagmiConfig config={wagmiConfig}>
        {/*<div style={{position: 'fixed', zIndex: 1000}}><Web3Button/></div>*/}
        <App />
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          '--w3m-z-index': '1301',
        }}
      />
    </Provider>
    {/*</React.StrictMode>*/}
  </GoogleOAuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
