import { toast } from 'App'
import PaymentCurrencyStore from 'store/paymentCurrency'
import LoadingStore from 'store/loading'
import WalletConnectStore from 'store/walletConnect'
import ProfileStore from 'store/profile'
import {
  ABI_ERC20_GOERLI,
  ABI_ERC721_GOERLI,
  ABI_ERC721_MATIC,
  ABI_ERC721_UNISWAP_ETHEREUM,
  EthereumChainId,
  LoginProvider,
  PolygonChainId,
} from '../const'
import { ethers } from 'ethers'

const { ethereum } = window

interface IObjectERC721 {
  AuthencityObjectERC721: any
  chainId: number | undefined
}

interface IObjectERC721WithWallet extends IObjectERC721 {
  userWallet: string
}

export const getAuthencityObjectERC721AndUserWallet =
  async (): Promise<IObjectERC721WithWallet> => {
    if (
      ProfileStore.getLoginProvider() === LoginProvider.Metamask &&
      ethereum
    ) {
      const { AuthencityObjectERC721, chainId } =
        await getMMAuthencityObjectERC721()

      await ethereum.request({ method: 'eth_requestAccounts' }) //-◄-Enable ethereum
      const userWallets = await ethereum.request({ method: 'eth_accounts' })
      const userWallet = userWallets[0]

      return { AuthencityObjectERC721, chainId, userWallet }
    }

    if (
      ProfileStore.getLoginProvider() === LoginProvider.WalletConnect &&
      WalletConnectStore.provider?.connected
    ) {
      const { AuthencityObjectERC721, chainId } =
        await getWCAuthencityObjectERC721()
      const userWallet = WalletConnectStore.address
      return { AuthencityObjectERC721, chainId, userWallet }
    }

    return { AuthencityObjectERC721: '', userWallet: '', chainId: undefined }
  }

export const getMMAuthencityObjectERC721 = async (): Promise<IObjectERC721> => {
  const provider = new ethers.BrowserProvider(ethereum)
  const signer = await provider.getSigner()
  const { chainId } = await provider.getNetwork()
  console.log('chainId MM', chainId)
  const chainIdNumber = Number(chainId)

  let AuthencityObjectERC721: any

  if (chainIdNumber === EthereumChainId) {
    AuthencityObjectERC721 = new ethers.Contract(
      process.env.REACT_APP_ERC721_ADDRESS_OBJECT ?? '',
      ABI_ERC721_GOERLI,
      signer
    )
  } else if (chainIdNumber === PolygonChainId) {
    AuthencityObjectERC721 = new ethers.Contract(
      process.env.REACT_APP_ERC721_ADDRESS_OBJECT_MATIC ?? '',
      ABI_ERC721_MATIC,
      signer
    )
  }

  return {
    AuthencityObjectERC721,
    chainId: chainIdNumber >= 0 ? chainIdNumber : undefined,
  }
}

export const getWCAuthencityObjectERC721 = async (): Promise<IObjectERC721> => {
  const { signer } = await WalletConnectStore.getWeb3ProviderAndSigner()

  const chainId = WalletConnectStore.provider?.chainId
  console.log('chainId WC', chainId)
  const chainIdNumber = Number(chainId)
  let AuthencityObjectERC721

  if (chainIdNumber === EthereumChainId) {
    AuthencityObjectERC721 = new ethers.Contract(
      process.env.REACT_APP_ERC721_ADDRESS_OBJECT ?? '',
      ABI_ERC721_GOERLI,
      signer
    )
  } else if (chainIdNumber === PolygonChainId) {
    AuthencityObjectERC721 = new ethers.Contract(
      process.env.REACT_APP_ERC721_ADDRESS_OBJECT_MATIC ?? '',
      ABI_ERC721_MATIC,
      signer
    )
  }

  return {
    AuthencityObjectERC721,
    chainId: chainIdNumber >= 0 ? chainIdNumber : undefined,
  }
}

export const getUniswapObjectERC721 = async () => {
  const provider = new ethers.BrowserProvider(ethereum)
  const signer = await provider.getSigner()

  const { chainId } = await provider.getNetwork()
  if (Number(chainId) === EthereumChainId) {
    const UniswapObjectERC721 = new ethers.Contract(
      process.env.REACT_APP_ERC20_ADDRESS_OBJECT ?? '',
      ABI_ERC20_GOERLI,
      signer
    )
    return { UniswapObjectERC721, signer }
  } else {
    return toast({
      type: 'warning',
      message: 'Change your network to Ethereum',
    })
  }
}

export const getUniswapObjectERC721WalletConnect = async () => {
  // const {signer} = await WalletConnectStore.getWeb3ProviderAndSigner()
  // const chainId = await signer.getChainId()
  // if (chainId === EthereumChainId) {
  //   const UniswapObjectERC721 = new ethers.Contract(
  //     process.env.REACT_APP_UNISWAP_CONTRACT_ADDRESS,
  //     ABI_ERC721_UNISWAP_ETHEREUM,
  //     signer
  //   )
  //   return ({ UniswapObjectERC721, signer })
  // } else {
  //   return toast({
  //     type: 'warning',
  //     message: 'Change your network to Ethereum'
  //   })
  // }
}
