import { response } from 'express'
import { makeAutoObservable } from 'mobx'
import { temp } from 'three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements'

import {
  apiError,
  customHistory,
  LoginProvider,
  SocialConnect,
  TRenderType,
} from '../utils'
import { api } from 'utils/config'

import { ILoginResponse, ISignUp } from '../models'

import ProfileStore from './profile'
import SignalRStore from './signalR'
import WidgetStore from './widget'

class LoginStore {
  constructor() {
    makeAutoObservable(this)
  }

  setLoginData(data: ILoginResponse, twoFATrust?: number | null) {
    if (data) {
      const { Item1: user, Item2: token } = data
      if (typeof twoFATrust === 'number') {
        if (twoFATrust > 0) {
          ProfileStore.setSessionTokenData(token, twoFATrust)
          ProfileStore.setSessionProfileData(user)
        } else {
          ProfileStore.setOnlySessionTokenData(token)
          ProfileStore.setOnlySessionProfileData(user)
        }
      } else {
        ProfileStore.setSessionTokenData(token)
        ProfileStore.setSessionProfileData(user)
      }
    }
  }

  async loginGoogle(tokenId: string) {
    const response = await api.post(`api/Account/googleLogin`, null, {
      params: {
        tokenId,
        skipTwoFactor: false,
      },
    })

    if (response.status !== 200) apiError(response)

    if (response.data.IsAuthenticatorConnected || response.data.IsSMSEnabled) {
      // Check if profile store has saved trust for this user. If this date is valid just make new login request
      if ((+ProfileStore?.trust?.[response?.data?.Id] || 0) > Date.now()) {
        const responseNew = await api.post(`api/Account/googleLogin`, null, {
          params: {
            tokenId,
            skipTwoFactor: true,
          },
        })

        if (responseNew.status !== 200) apiError(responseNew)

        this.setLoginData(responseNew.data)
        return responseNew.data
      } else {
        customHistory.push(`/login/${response.data.NickName}`, response.data)
        return null
      }
    } else {
      this.setLoginData(response.data)
      return response.data
    }
  }

  async signUpGoogle(tokenId: string) {
    const response = await api.post(`api/Account/googleSignUp`, null, {
      params: {
        tokenId,
      },
    })

    if (response.status !== 200) apiError(response)

    this.setLoginData(response.data)
    return response.data
  }

  async loginApple(tokenId: string) {
    const response = await api.post(`api/Account/appleLogin`, null, {
      params: {
        tokenId,
        skipTwoFactor: false,
      },
    })

    if (response.status !== 200) apiError(response)

    if (response.data.IsAuthenticatorConnected || response.data.IsSMSEnabled) {
      if ((+ProfileStore?.trust?.[response?.data?.Id] || 0) > Date.now()) {
        const responseNew = await api.post(`api/Account/appleLogin`, null, {
          params: {
            tokenId,
            skipTwoFactor: true,
          },
        })

        if (responseNew.status !== 200) apiError(responseNew)

        this.setLoginData(responseNew.data)
        return responseNew.data
      } else {
        customHistory.push(`/login/${response.data.NickName}`, response.data)
        return null
      }
    } else {
      this.setLoginData(response.data)
      return response.data
    }
  }

  async signUpApple(tokenId: string) {
    const response = await api.post(`api/Account/appleSignUp`, null, {
      params: {
        tokenId,
      },
    })

    if (response.status !== 200) apiError(response)

    this.setLoginData(response.data)
    return response.data
  }

  // Old logic. Was used with TwitterCallback
  async loginTwitter(
    tokenId: string,
    tokenVerifier: string,
    skipTwoFactor: boolean = false
  ) {
    const response = await api.post(`api/Account/twitterLogin`, null, {
      params: {
        tokenId,
        tokenVerifier,
        skipTwoFactor,
      },
    })

    if (response.status !== 200) apiError(response)

    if (response.data.IsAuthenticatorConnected || response.data.IsSMSEnabled) {
      if ((+ProfileStore?.trust?.[response?.data?.Id] || 0) > Date.now()) {
        const responseNew = await api.post(`api/Account/twitterLogin`, null, {
          params: {
            tokenId,
            tokenVerifier,
            skipTwoFactor: true,
          },
        })

        if (responseNew.status !== 200) apiError(responseNew)

        this.setLoginData(responseNew.data)
        return responseNew.data
      } else {
        customHistory.push(`/login/${response.data.NickName}`, response.data)
        return null
      }
    } else {
      this.setLoginData(response.data)
      return response.data
    }
  }

  async twitterLogin(userId: string, skipTwoFactor: boolean) {
    const response = await api.post(`api/Account/twitterLogin`, null, {
      params: {
        userId,
        skipTwoFactor,
      },
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async authTwitter(userId: string, token: string) {
    ProfileStore.setSessionTokenData(token)

    const response = await ProfileStore.getProfileByToken()

    if (
      (response.IsAuthenticatorConnected || response.IsSMSEnabled) &&
      (+ProfileStore?.trust?.[response?.Id] || 0) < Date.now()
    ) {
      ProfileStore.setToken('')

      const responseLogin = await this.twitterLogin(userId, false)

      customHistory.push(`/login/${response.NickName}`, responseLogin)

      return null
    } else {
      const temp = {
        Item1: response,
        Item2: token,
      }

      this.setLoginData(temp)

      return temp
    }
  }

  async signUpTwitter(tokenId: string, tokenVerifier: string) {
    const response = await api.post(`api/Account/twitterSignUp`, null, {
      params: {
        tokenId,
        tokenVerifier,
      },
    })

    if (response.status !== 200) apiError(response)

    this.setLoginData(response.data)
    return response.data
  }

  async loginWallet(
    wallet: string,
    hash: string,
    provider: LoginProvider,
    skipTwoFactor: boolean = false,
    renderType: TRenderType = 'default'
  ) {
    const endpointName = renderType === 'widget' ? 'Widget' : 'Account'

    const response = await api.post(
      `api/${endpointName}/login`,
      {
        Wallet: wallet,
        Hash: hash,
      },
      {
        params: {
          provider,
          skipTwoFactor,
        },
        headers: WidgetStore.getWidgetAuthHeaders(),
      }
    )

    if (response.status !== 200) apiError(response)

    if (
      (response.data.IsAuthenticatorConnected || response.data.IsSMSEnabled) &&
      !skipTwoFactor
    ) {
      if ((+ProfileStore?.trust?.[response?.data?.Id] || 0) > Date.now()) {
        const responseNew = await api.post(
          `api/${endpointName}/login`,
          {
            Wallet: wallet,
            Hash: hash,
          },
          {
            params: {
              provider,
              skipTwoFactor: true,
            },
            headers: WidgetStore.getWidgetAuthHeaders(),
          }
        )

        if (responseNew.status !== 200) apiError(responseNew)

        this.setLoginData(responseNew.data)
        return responseNew.data
      } else {
        customHistory.push(`/login/${response.data.NickName}`, response.data)
        return null
      }
    } else {
      this.setLoginData(response.data)
      return response.data
    }
  }

  async connectWallet(wallet: string, provider: LoginProvider) {
    const response = await api.put(`api/Account/connectWallet`, null, {
      params: {
        wallet,
        provider,
      },
    })

    if (response.status !== 200) apiError(response)
    return response.data
  }

  async connectSocial(
    tokenId: string,
    provider: SocialConnect,
    tokenVerifier?: string
  ) {
    const response = await api.post(`api/Account/connectSocial`, null, {
      params: {
        tokenId,
        provider,
        tokenVerifier,
      },
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async disconnectSocial(provider: SocialConnect) {
    const response = await api.put(`api/Account/disconnectSocial`, null, {
      params: {
        provider,
      },
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async checkWalletIsRegisteredInSystem(wallet: string) {
    const response = await api.get(`api/Account/CheckRegistrationWallet`, {
      params: {
        wallet,
      },
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async getNonce(walletAddress: string) {
    const response = await api.get(`api/Account/getNonce`, {
      params: {
        walletAddress,
      },
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async createUser(
    data: ISignUp,
    reCaptchaToken: string,
    userWallet: string,
    registerType: TRenderType = 'default'
  ) {
    const endpoint = registerType === 'widget' ? 'Widget/register' : 'Account'
    let response: any

    if (data.dateOfBirth) {
      let birthDate = new Date(data.dateOfBirth)
      birthDate = new Date(
        birthDate.getTime() - birthDate.getTimezoneOffset() * 60000
      )
      data = { ...data, dateOfBirth: birthDate.toISOString() as any }
    }

    if (data.saveTwitterInfoId) {
      response = await api.post(`api/Account/createFromTwitter`, {
        NickName: data.nickname,
        Wallet: userWallet,
        SaveTwitterInfoId: data.saveTwitterInfoId,
        DateOfBirth: data.dateOfBirth,
      })
    } else {
      response = await api.post(
        `api/${endpoint}?reCaptchaToken=${reCaptchaToken}`,
        {
          FirstName: data.firstName,
          LastName: data.lastName,
          DateOfBirth: data.dateOfBirth,
          Email: data.email,
          Wallet: userWallet,
          NickName: data.nickname,
          InviteCode: data.invitationCode,
        },
        {
          headers: WidgetStore.getWidgetAuthHeaders(),
        }
      )
    }

    if (response.status !== 200) apiError(response)

    const newUser = response.data

    if (newUser.NickName && newUser.Id) {
      if (SignalRStore.connection) {
        try {
          await SignalRStore.connection.invoke('SendProfileInfo', newUser.Id)
        } catch (e) {
          console.log(e)
        }
      }
    }

    return response.data
  }

  async checkNickName(value: string | undefined) {
    try {
      const response = await api.get(
        `api/Account/CheckNickName?userNickName=${value}`
      )
      if (response) {
        return response.data
      }
    } catch (error) {
      return false
    }
  }

  async checkTwitterNickName(value: string | undefined) {
    try {
      const response = await api.get(
        `api/Account/CheckTwitterNickName?userNickName=${value}`
      )
      if (response) {
        return response.data
      }
    } catch (error) {
      return false
    }
  }

  async checkInvitationCode(value: string | undefined) {
    try {
      const response = await api.get(
        `api/Account/CheckInviteCode?code=${value}`
      )
      if (response) {
        return response.data
      }
    } catch (error) {
      return false
    }
  }

  // Don't need now ⬇︎⬇︎⬇︎
  async savePassword(password: string) {
    const response = await api.post(`api/Account/SavePassword`, null, {
      params: {
        password,
      },
    })
    if (response.status !== 200) apiError(response)

    return response.data
  }

  async loginPassword(Login: string, Password: string) {
    const response = await api.post(`api/Account/LoginPassword`, {
      Login,
      Password,
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }
}

export default new LoginStore()
