import { convertToRaw, EditorState } from 'draft-js'
import { makeAutoObservable } from 'mobx'

import packageInfo from '../../package.json'

import { IVersion } from '../utils/const'
import { apiError } from 'utils'
import { api } from 'utils/config'

import LoadingStore from './loading'

class AdminStore {
  versions: IVersion[] = []
  versionsList: string[] = []
  selectedVersion = 'All'
  currentAppVersion = packageInfo.version

  constructor() {
    makeAutoObservable(this)
  }

  setVersions(value: IVersion[]) {
    this.versions = value
  }

  setSelectedVersion(version: string) {
    this.selectedVersion = version
  }

  getVersion() {
    return this.versions.find(item => item.Version === this.selectedVersion)
  }

  getVersionById(id: number) {
    return this.versions.find(item => item.Id === id)
  }

  async getVersions() {
    const versionsData = ((await this.getVersionInfo()) as any).ListItems.map(
      (item: any) => {
        let text = []
        try {
          text = item.Text ? JSON.parse(item.Text).info : []
        } catch (e) {}
        return { ...item, Text: text }
      }
    )
    const versions = versionsData.map((item: any) => item.Version)
    this.versionsList = ['All', ...versions]
    this.versions = versionsData
  }

  async getVersionInfo() {
    LoadingStore.setLoading(true)
    const response = await api.get(`api/Admin/getVersionInfo`)
    LoadingStore.setLoading(false)

    if (response.status !== 200) apiError(response)

    return response.data
  }

  async addVersion(versionNumber: string, versionInfo: string) {
    LoadingStore.setLoading(true)

    const plainVersionInfo = versionInfo?.split('\n')?.filter(e => !!e)

    const response = await api.post(`api/Admin/addVersionInfo`, {
      Version: versionNumber,
      Text: JSON.stringify({ info: plainVersionInfo }),
    })

    LoadingStore.setLoading(false)

    if (response.status !== 200) apiError(response)

    this.versions = [
      {
        Id: Date.now(),
        Version: versionNumber,
        Text: plainVersionInfo,
        CreatedAt: new Date().toISOString(),
      },
      ...this.versions,
    ]

    this.versionsList.splice(1, 0, versionNumber)

    this.currentAppVersion = versionNumber
  }

  async editVersion(id: number, versionNumber: string, versionInfo: string) {
    LoadingStore.setLoading(true)

    const plainVersionInfo = versionInfo?.split('\n')?.filter(e => !!e)

    const response = await api.put(
      `api/Admin/editVersionInfo`,
      {
        Version: versionNumber,
        Text: JSON.stringify({ info: plainVersionInfo }),
      },
      {
        params: { Id: id },
      }
    )

    LoadingStore.setLoading(false)

    if (response.status !== 200) apiError(response)

    this.versions = this.versions?.map(v => {
      if (v.Id === id) {
        return {
          ...v,
          Version: versionNumber,
          Text: plainVersionInfo,
        }
      } else {
        return v
      }
    })

    const versions = this.versions.map((item: any) => item.Version)
    this.versionsList = ['All', ...versions]

    this.currentAppVersion = versionNumber
  }

  async getLastVersionNumber() {
    const response = await api.get(`api/Admin/getVersionInfo`)

    if (response.status !== 200) apiError(response)

    this.currentAppVersion = response.data?.ListItems?.[0]?.Version
  }
}

export default new AdminStore()
