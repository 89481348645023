import { observable, action, makeAutoObservable } from 'mobx'

class TransactionStore {
  isActive: boolean = false


  constructor() {
    makeAutoObservable(this)
  }


  setIsActive(value: boolean) {
    this.isActive = value
  }
}

export default new TransactionStore()
