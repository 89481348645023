import { action, makeAutoObservable, observable, runInAction } from 'mobx'
import { api } from 'utils/config'
import type {
  IDisabledNotificationTypes,
  INotification,
} from 'models/notification'
import { defaultDisabledNotificationsTypes, TabType } from 'utils'
import { IPublication } from '../models'
import { IComment } from '../models/comments'

class NotificationStore {
  notifications: INotification[] = []
  publicationsForNotifications: IPublication[] = []
  notificationsCountUnread: number = 0
  currentPage: number = 0
  totalPages: number = 0
  startTotalItems: number = 0
  disabledTypes: IDisabledNotificationTypes = defaultDisabledNotificationsTypes

  constructor() {
    makeAutoObservable(this)
  }

  setDisabledTypes(value: IDisabledNotificationTypes) {
    this.disabledTypes = value
  }

  setNotificationsCountUnread(value: number) {
    this.notificationsCountUnread = value
  }

  setNotifications(value: INotification[]) {
    this.notifications = value
  }

  setPublicationsForNotifications(value: IPublication[]) {
    this.publicationsForNotifications = value
  }

  setCurrentPage(value: number) {
    this.currentPage = value
  }

  setTotalPages(value: number) {
    this.totalPages = value
  }

  setStartTotalItems(value: number) {
    this.startTotalItems = value
  }

  getNotificationType(value: TabType) {
    switch (value) {
      case TabType.all:
        return 0
      case TabType.like:
        return 1
      case TabType.comment:
        return 2
      case TabType.repost:
        return 3
      case TabType.backedPosts:
        return 4
      case TabType.favorite:
        return 6
      case TabType.backedProfile:
        return 7
      case TabType.videos:
        return 8
      case TabType.bids:
        return 9
      default:
        return 0
    }
  }

  async getUnreadNotifications() {
    const response = await api.get(`api/Notification/getLast`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description ||
            response.data?.Title ||
            response.data?.Titlle ||
            'Some error')
      )

    return response.data
  }

  async getNotification(
    startTotalItems: number,
    pageNumber: number,
    type: number,
    pageSize: number
  ) {
    const response = await api.get(`api/Notification`, {
      params: {
        startTotalItems,
        pageNumber,
        pageSize,
        type,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description ||
            response.data?.Title ||
            response.data?.Titlle ||
            'Some error')
      )

    return response.data
  }

  async deleteNotifications(value: Array<string | number>) {
    const response = await api.delete(`api/Notification`, {
      data: value,
    })
    if (response.status !== 204)
      throw new Error(
        response.data &&
          (response.data.Description ||
            response.data?.Title ||
            response.data?.Titlle ||
            'Some error')
      )

    return response.data
  }

  async disableNotificationByType(value: number) {
    const response = await api.get(
      `api/Notification/disableNotificationByType`,
      {
        params: {
          type: value,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description ||
            response.data?.Title ||
            response.data?.Titlle ||
            'Some error')
      )

    return response.data
  }

  async deleteVideoNotifications(value: number[]) {
    const response = await api.delete(`api/Room/removeVideoNotification`, {
      data: value,
    })
    if (response.status !== 204)
      throw new Error(
        response.data &&
          (response.data.Description ||
            response.data?.Title ||
            response.data?.Titlle ||
            'Some error')
      )

    return response.data
  }
}

export default new NotificationStore()
