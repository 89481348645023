import React, { CSSProperties } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  style: CSSProperties
  text: string
  styleText: CSSProperties
  img?: string
  altImg?: string
  imgStyle?: CSSProperties
  btnStyle: 'classic' | 'bordered' | 'classicDark' | 'disabled'
  svgImage?: boolean
  type?: 'submit' | 'button'
  onClick?: (e?: any) => void
  blueHover?: boolean
  disabled?: boolean
  [x: string]: any
}

//Resize
const Button: React.FC<IProps> = ({
  btnStyle,
  style,
  text,
  img,
  altImg = 'btnLogo',
  styleText = { color: '#2e2b63' },
  imgStyle,
  svgImage,
  type = 'button',
  onClick,
  blueHover,
  disabled,
  ...rest
}) => {
  return (
    <button
      disabled={disabled}
      onClick={() => onClick && onClick()}
      type={type}
      style={style}
      className={cn({
        [styles.btn]: btnStyle === 'classic',
        [styles.btnBordered]: btnStyle === 'bordered',
        [styles.btnBlueHover]: blueHover,
        [styles.classicDark]: btnStyle === 'classicDark',
        [styles.btnDisabled]: btnStyle === 'disabled',
      })}
      {...rest}
    >
      {img && (
        <img
          style={imgStyle}
          src={img}
          alt={altImg}
          className={styles.btnIcon}
        />
      )}
      {svgImage && <div className={styles.btnIconSvg} style={imgStyle} />}
      <div
        style={styleText}
        className={cn({
          [styles.btnText]: true,
          [styles.textBtnHover]: btnStyle === 'classic' && !img,
        })}
      >
        {text}
      </div>
    </button>
  )
}

Button.displayName = 'Button'

export default Button
