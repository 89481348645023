import { action, makeAutoObservable, observable } from 'mobx'
import { api } from 'utils/config'
import { IFollowing } from 'models'

class FollowingStore {
  followingList: IFollowing[] = []
  followingListMainPage: string[] = []

  constructor() {
    makeAutoObservable(this)
  }


  setFollowingList(value: IFollowing[]) {
    this.followingList = value
  }

  
  setFollowingListMainPage(value: string[]) {
    this.followingListMainPage = value
  }

  
  async addInFollowing(followerUserId: string) {
    const response = await api.post(
      `/api/Account/AddFollowing`,
      null,
      {
        params: {
          followerUserId,
        },
      },
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  async deleteFromFollowing(followingId: string) {
    const response = await api.delete(
      `/api/Account/RemoveFollowing`,
      {
        params: {
          followingId,
        },
      },
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }


  
  async getFollowingList(userId: string) {
    const response = await api.get(
      `/api/Account/GetFollowingList`,
      {
        params: {
          userId,
        },
      },
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  async GetFollowersList(userId: string) {
    const response = await api.get(
      `/api/Account/GetFollowersList`,
      {
        params: {
          userId,
        },
      },
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  async getFollowingForMainPage() {
    const response = await api.get(`/api/Account/getFollowingForMainPage`)
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }
}

export default new FollowingStore()
