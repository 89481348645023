import { action, makeAutoObservable } from 'mobx'
import { api } from 'utils/config'

class BidStore {
  constructor() {
    makeAutoObservable(this)
  }


  async createBid(publicationId: number, price: number) {
    const response = await api.post(
      `api/Bid/createBid`,
      null,
      {
        params: { publicationId, price }
      }
    )
    if (response.status !== 201)
      throw new Error(
        response.data && (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }


  async applyBid(bidId: number) {
    const response = await api.put(`api/Bid/applyBid?bidId=${bidId}`)

    if (response.status !== 200)
      throw new Error(
        response.data && (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }


  async applySystemBid(bidId: number, hash: string) {
    const response = await api.put(`api/Bid/applySystemBid`,
      null,
      { params: { bidId, hash } })

    if (response.status !== 200)
      throw new Error(
        response.data && (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }


  async checkSystemBalance(bidId: number) {
    const response = await api.get(`api/Bid/checkSystemBalance?bidId=${bidId}`)

    if (response.status !== 200)
      throw new Error(
        response.data && (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }


  async getAllBidCreators(publicationId: number) {
    const response = await api.get(`api/Bid/getAllBidCreators?publicationId=${publicationId}`)

    if (response.status !== 200)
      throw new Error(
        response.data && (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }
}

export default new BidStore()
