import { action, makeAutoObservable } from 'mobx'

import { api } from 'utils/config'

import { toast } from 'App'

import { IPublication } from 'models'

import LiveFeedStore from 'store/liveFeed'
import NotificationStore from 'store/notification'
import PageActivitiesStore from 'store/pageActivities'
import ProfileStore from 'store/profile'
import PublicationStore from 'store/publication'
import TopStore from 'store/top'
import WidgetStore from 'store/widget'

import Marketplace from './marketplace'

class LikeStore {
  constructor() {
    makeAutoObservable(this)
  }

  async addDeleteLike(LikeObjectId: number, BlockchainId: number) {
    const response = await api.post(`api/Like/CreateLikePublication`, {
      BlockchainId,
      LikeObjectId,
    })

    if (response.status === 200 || response.status === 204) {
      return response.data
    } else {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
  }

  toggleLike = (publicationId: number, Liked: boolean, LikeCount: number) => {
    const updatedPosts = (posts: IPublication[]) => {
      return posts.map(item =>
        item.Id === publicationId
          ? {
              ...item,
              Liked: !Liked,
              LikeCount: Liked ? LikeCount - 1 : LikeCount + 1,
            }
          : item
      )
    }

    const updatedPublicationsForNotifications = (
      publications: IPublication[]
    ) => {
      return publications.map(item => {
        return item.Id === publicationId
          ? {
              ...item,
              LikeCount: item.Liked ? item.LikeCount - 1 : item.LikeCount + 1,
              Liked: !item.Liked,
            }
          : item
      })
    }

    NotificationStore.setPublicationsForNotifications(
      updatedPublicationsForNotifications(
        NotificationStore.publicationsForNotifications
      )
    )
    PublicationStore.setPublication(
      updatedPosts(PublicationStore.publicationList)
    )
    PublicationStore.setThreadPublications(
      updatedPosts(PublicationStore.threadPublications)
    )
    PublicationStore.setHashtagPublications(
      updatedPosts(PublicationStore.hashtagPublications)
    )
    LiveFeedStore.setPublications(updatedPosts(LiveFeedStore.publications))
    TopStore.setPublications(updatedPosts(TopStore.publications))
    Marketplace.setTradingPublications(
      updatedPosts(Marketplace.tradingPublications)
    )
    PageActivitiesStore.setSelectedProfilePageData({
      ...PageActivitiesStore.selectedProfilePageData,
      posts: updatedPosts(PageActivitiesStore.selectedProfilePageData.posts),
    })
    WidgetStore.setPublications(updatedPosts(WidgetStore.publications))
  }

  async handlerLike(
    publicationId: number,
    BlockchainId: number,
    Liked: boolean,
    LikeCount: number,
    activeTab: string,
    isSinglePost?: boolean
  ) {
    if (!ProfileStore.isAuthorizedProfileToast()) return
    if (activeTab === 'Tweets') return
    try {
      this.toggleLike(publicationId, Liked, LikeCount)

      await this.addDeleteLike(publicationId, Number(BlockchainId))

      //Think about it
      // if (isSinglePost) {
      // 	PublicationStore.setPublication(updatedPosts(PublicationStore.publicationList))
      // 	PublicationStore.setThreadPublications(updatedPosts(PublicationStore.threadPublications))
      // 	PublicationStore.setHashtagPublications(updatedPosts(PublicationStore.publicationListByHashtag))
      // } else {
      // 	PublicationStore.setPublication(updatedPosts(PublicationStore.publicationList))
      // }
    } catch (e: any) {
      this.toggleLike(
        publicationId,
        !Liked,
        Liked ? LikeCount - 1 : LikeCount + 1
      )

      toast({
        type: 'error',
        message: e.message || `Error when liking a post`,
      })
    }
  }

  async addDeleteLikeComment(LikeObjectId: number, BlockchainId: number) {
    const response = await api.post(`api/Like/CreateLikeComment`, {
      BlockchainId,
      LikeObjectId,
    })

    if (response.status === 200 || response.status === 204) {
      return response.data
    } else {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
  }
}

export default new LikeStore()
