import { action, makeAutoObservable } from 'mobx'
import {api} from 'utils/config'

class SystemStatistic {
	constructor() {
		makeAutoObservable(this)
	}


	async getSystemStatistic() {
		const response = await api.get(`api/WeeklyStatistic`)

		if (response.status !== 200)
			throw new Error(
				response.data && (response.data.Description || response.data.Title || 'Some error')
			)
		return response.data
	}

}

export default new SystemStatistic()
