import { action, makeAutoObservable } from 'mobx'
import { api } from 'utils'

class DonateStore {
  constructor() {
    makeAutoObservable(this)
  }


  async createDonate(UserIdTo: string, AuthencityCount: string) {
    const response = await api.post(`api/Donate/CreateIdentityBacking`, {
      UserIdTo,
      AuthencityCount: Number(AuthencityCount)
    })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }


  async createDonatePost(PublicationId: number, AuthencityCount: string) {
    const response = await api.post(`api/Donate/CreateMessageBacking`, {
      PublicationId: Number(PublicationId),
      AuthencityCount: Number(AuthencityCount)
    })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))
    return response.data
  }


  async createIdentityDonateFromWallet(UserIdTo: string, AuthencityCount: string, transactionHash: string) {
    const response = await api.post(`api/Donate/CreateIdentityBackingFromWallet`, {
      UserIdTo,
      AuthencityCount: Number(AuthencityCount)
    }, {
      params: {
        transactionHash
      }
    })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }


  async createPostDonateFromWallet(PublicationId: number, AuthencityCount: string, transactionHash: string) {
    const response = await api.post(`api/Donate/CreateMessageBackingFromWallet`, {
      PublicationId: Number(PublicationId),
      AuthencityCount: Number(AuthencityCount)
    }, {
      params: {
        transactionHash
      }
    })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }

  async createTwitterUserDonate(nickname: string, amount: number, text: string) {
    const response = await api.post(`api/Donate/CreateTwitterUserBacking`, {
      Amount: amount,
      Nickname: nickname,
      Text: text
    })

    if (response.status === 400 && response.data.Title.includes('This user has already registered on Authencity')) {
      return {nickname: response.data.Title.split('/user/')[1]}
    }

    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }


  async getUserStatisticShort(userId: string) {
    const response = await api.get(`api/Donate/BackersInfo?id=${userId}`)
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }


  async getBackedByUserInfo(userId: string) {
    const response = await api.get(`api/Account/GetAuthCountByUserId?userId=${userId}`)
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }


  async getBackers(pageNumber: number, typeData: { type: number, userId?: string, publicationId?: number }) {
    const response = await api.get(`api/Account/getBackersInfo`, {
      params: {
        pageSize: 10,
        pageNumber: pageNumber,
        ...typeData
      }
    })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }

  async createSuperpostDonate(publicationId: number) {
    const response = await api.post(`api/Donate/createSuperpostBacking?publicationId=${publicationId}`,)
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }
}

export default new DonateStore()
