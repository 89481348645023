import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { ActivatedUser } from 'components/ActivatedUser'

import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'

import defaultUserPhoto from 'sources/images/defaultUser.svg'

import styles from './styles.module.scss'

interface IProps {

}

export const User: FC<IProps> = observer(() => {
  const navigate = useNavigate()
  const { profile, pageActivities } = useStore()
  const {NickName, ImageLink, FirstName, LastName, Activated} = profile.selectedUser

  const redirectToProfile = () => {
    if (NickName) {
      pageActivities.setIsClickBack(false)
      navigate(`/user/${NickName}`)
    }
  }

  const userAvatarImage = ImageLink ? ImageLink : defaultUserPhoto
  const userFullName = `${FirstName ? FirstName : ''} ${LastName ? LastName : ''}`

  return (
    <div className={styles.userContainer}>
      <IconButton color="inherit"
                  aria-label={`open menu`}
                  onClick={redirectToProfile}
                  sx={{padding: 0}}
      >
        <Avatar
          alt="avatar"
          src={userAvatarImage}
          sx={{ width: 56, height: 56 }}
        />
      </IconButton>
      <div className={styles.nameBox} onClick={redirectToProfile}>
        <div className={styles.name}>{userFullName}</div>
        <div className={styles.nickNameBlock}>
          <div className={styles.nickName}>@{NickName}</div>
          <ActivatedUser style={{ marginTop: '2px' }}
                         activated={Activated}
                         deactivatedMode
                         currentUser={profile.viewedUser.Id === profile.selectedUser.Id}/>
        </div>
      </div>
    </div>
  )
})
