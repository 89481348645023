import React, { FC, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'

import { clickCookies } from 'utils'

import cookieImg from 'sources/images/coockie.svg'

import styles from './styles.module.scss'


export const Cookie: FC = () => {
  const [open, setOpen] = React.useState(false)

  useEffect(() => {
    const cookie = getCookie('cookieModal')
    !cookie && setOpen(true)
  }, [])

  const getCookie = (value: string) => {
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + value.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, value: string) => {
    const maxAgeYear = 60*60*24*365
    document.cookie = `cookieModal=${value}; max-age=${maxAgeYear}; path=/;`
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      classes={{ root: styles.snackbar }}
      ContentProps={{
        classes: {
          root: styles.contentRoot,
          action: styles.action,
          message: styles.message
        }
      }}
      message={
        <div onClick={clickCookies} className={styles.cookieMessage}>
          <img src={cookieImg} alt="cookie" className={styles.cookie} />
          <div>We use third-party cookies in order to personalize your site experience</div>
        </div>
      }
      action={
        <div className={styles.btnBox}>
          <Button color="default" size="small" onClick={(e) => handleClose(e, 'Decline')}>
            Decline
          </Button>
          <Button color="default" size="small" variant="outlined" onClick={(e) => handleClose(e, 'Allow')}>
            Allow
          </Button>
        </div>
      }
    />
  )
}
