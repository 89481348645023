export const clickCoinGeckoAUTH = () => {
  const win = window.open(
    // 'https://www.coingecko.com/en/coins/authencity'
    'https://www.geckoterminal.com/eth/pools/0x452b98f7a0f9d105898d9b19fddd0ae92c4bd9b2',
    '_blank'
  )
  if (win) {
    win.focus()
  }
}
