import React, { useEffect, useState } from 'react'

export const useWheelScroll = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  useEffect(() => {
    const onWheelScroll = (event: WheelEvent) => {
      event.preventDefault()

      let [x, y] = [event.deltaX, event.deltaY]
      let magnitude

      const scrollLeft = ref.current?.scrollLeft ?? 0
      const clientWidth = ref.current?.clientWidth ?? 0
      const scrollWidth = ref.current?.scrollWidth ?? 0

      if (scrollLeft + clientWidth === scrollWidth && y < 0) return

      if (x === 0) {
        magnitude = y > 0 ? -8 : 8
      } else {
        magnitude = x
      }

      ref.current?.scrollBy({
        left: magnitude
      })
    }

    ref.current?.addEventListener('wheel', onWheelScroll)

    return () => {
      ref.current?.removeEventListener('wheel', onWheelScroll)
    }
  }, [ref.current])
}
