import React from 'react'

import { convertToRaw, EditorState } from 'draft-js'
import { makeAutoObservable } from 'mobx'
import { v4 as uuid } from 'uuid'

import { api, IFAQChapter, IFAQParagraph } from 'utils'

import LoadingStore from './loading'

// const faqChapterInit: IFAQChapter = {
//   Id: '',
//   Position: 0,
//   Paragraphs: [],
//   Title: '',
//   Type: 0,
// }
//
// const faqParagraphInit: IFAQParagraph = {
//   Id: '',
//   ChapterId: '',
//   Position: 0,
//   Title: '',
//   Description: '',
//   Subtitle: '',
//   Type: 0,
//   Steps: [],
// }

class FAQ {
  faq: IFAQChapter[] = []
  currentFaqChapterId = ''
  currentFaqParagraphId: string | undefined = ''

  constructor() {
    makeAutoObservable(this)
  }

  setFaq(value: IFAQChapter[]) {
    this.faq = value
  }

  setCurrentFaqChapterId(id: string) {
    this.currentFaqChapterId = id
  }

  setCurrentFaqParagraphId(id: string | undefined) {
    this.currentFaqParagraphId = id
  }

  getFirstFaqChapterId() {
    return this.faq?.[0]?.Id || ''
  }

  getFirstFaqParagraphId() {
    return this.faq?.[0]?.Paragraphs?.[0]?.Id || ''
  }

  getCurrentFaqChapterId() {
    return this.faq?.find?.(e => e?.Id === this.currentFaqChapterId)?.Id || ''
  }

  getCurrentFaqChapterFirstParagraphId() {
    return (
      this.faq?.find?.(e => e?.Id === this.currentFaqChapterId)?.Paragraphs?.[0]
        ?.Id || ''
    )
  }

  getCurrentFaqParagraphId() {
    return (
      this.faq
        ?.find?.(e => e?.Id === this.currentFaqChapterId)
        ?.Paragraphs?.find(e => e?.Id === this.currentFaqParagraphId)?.Id || ''
    )
  }

  getCurrentFaqChapter() {
    return this.faq?.find?.(e => e?.Id === this.currentFaqChapterId)
  }

  getCurrentFaqParagraph() {
    return this.faq
      ?.find?.(e => e?.Id === this.currentFaqChapterId)
      ?.Paragraphs?.find(e => e?.Id === this.currentFaqParagraphId)
  }

  getCurrentFaqAnchors() {
    const description = this.getCurrentFaqParagraph()?.Description

    if (description) {
      try {
        const descriptionObj = JSON.parse(description)

        return descriptionObj?.blocks?.filter((e: any) =>
          e?.type?.startsWith('header-')
        )
      } catch (e) {
        console.log('get current faq anchors', e)

        return []
      }
    } else {
      return []
    }
  }

  async getFAQ() {
    LoadingStore.setLoading(true)

    const response = await api.get(`api/FAQ/getAll`)

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return (response.data as IFAQChapter[])
      ?.map(chapter => {
        return {
          ...chapter,
          Paragraphs: chapter?.Paragraphs?.sort(
            (a, b) => a.Position - b.Position
          ),
        }
      })
      .sort((a, b) => a.Position - b.Position)
  }

  // CHAPTERS

  async addChapter(chapterTitle: EditorState): Promise<IFAQChapter> {
    const chapter: IFAQChapter = {
      Title: JSON.stringify(convertToRaw(chapterTitle.getCurrentContent())),
      Id: uuid(),
      Paragraphs: [],
      Type: 0,
      Position: (this.faq?.length || 0) + 1,
    }

    LoadingStore.setLoading(true)
    const response = await api.post(`api/FAQ/AddChapter`, {
      Title: chapter.Title,
      Position: chapter.Position,
    })

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    chapter.Id = response?.data?.Id

    this.faq = [...this.faq, chapter]

    return chapter
  }

  async editChapter(chapterTitle: EditorState) {
    const newTitle = JSON.stringify(
      convertToRaw(chapterTitle.getCurrentContent())
    )

    LoadingStore.setLoading(true)

    const response = await api.put(
      `api/FAQ/editChapter`,
      {
        Title: newTitle,
        Position: this.getCurrentFaqChapter()?.Position || 0,
      },
      {
        params: {
          id: this.currentFaqChapterId,
        },
      }
    )

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq?.map(e => {
      if (e?.Id === this.currentFaqChapterId) {
        return {
          ...e,
          Title: newTitle,
        }
      }

      return e
    })
  }

  async moveChapter(newArray: Array<{ id: string; index: number }>) {
    LoadingStore.setLoading(true)

    const response = await api.put(
      `api/FAQ/editListOfChapters`,
      newArray?.map(e => ({
        Id: e?.id,
        Position: e?.index,
      }))
    )

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq
      ?.map(chapter => {
        const arr = newArray.find((e: any) => {
          return e?.id === String(chapter?.Id)
        })

        const newIndex: number = arr?.index || 0

        return {
          ...chapter,
          Position: newIndex,
        }
      })
      .sort((a, b) => a.Position - b.Position)
  }

  async deleteChapter() {
    LoadingStore.setLoading(true)

    const response = await api.delete(`api/FAQ/deleteChapter`, {
      params: {
        id: this.currentFaqChapterId,
      },
    })

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq?.filter(e => e?.Id !== this.currentFaqChapterId)
  }

  // PARAGRAPHS

  async addParagraph(
    chapterId: string,
    paragraphTitle: EditorState
  ): Promise<IFAQParagraph> {
    LoadingStore.setLoading(true)

    const paragraph: IFAQParagraph = {
      Title: JSON.stringify(convertToRaw(paragraphTitle.getCurrentContent())),
      Id: uuid(),
      Description: '',
      Steps: [],
      ChapterId: chapterId,
      Subtitle: '',
      Type: 0,
      Position:
        (this.faq?.find(e => e?.Id === chapterId)?.Paragraphs?.length || 0) + 1,
    }

    const response = await api.post(`api/FAQ/addParagraph`, {
      ChapterId: paragraph?.ChapterId,
      Title: paragraph?.Title,
      Description: paragraph?.Description,
      Position: paragraph?.Position,
    })

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    paragraph.Id = response?.data?.Id

    this.faq = this.faq?.map(e => {
      if (e?.Id === chapterId) {
        return { ...e, Paragraphs: [...e?.Paragraphs, paragraph] }
      }

      return e
    })

    return paragraph
  }

  async editParagraphTitle(paragraphId: string, paragraphTitle: EditorState) {
    LoadingStore.setLoading(true)

    const newTitle = JSON.stringify(
      convertToRaw(paragraphTitle.getCurrentContent())
    )
    const currentParagraph = this.getCurrentFaqParagraph()

    const response = await api.put(
      `api/FAQ/editParagraph`,
      {
        Title: newTitle,
        Description: currentParagraph?.Description,
        Position: currentParagraph?.Position || 0,
      },
      {
        params: {
          id: this.currentFaqParagraphId,
        },
      }
    )

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq?.map(e => {
      if (e?.Id === this.currentFaqChapterId) {
        return {
          ...e,
          Paragraphs: e?.Paragraphs?.map(p => {
            if (p?.Id === paragraphId) {
              return {
                ...p,
                Title: newTitle,
              }
            }

            return p
          }),
        }
      }

      return e
    })
  }

  async editParagraph(
    paragraphTitle: EditorState,
    paragraphDescription: EditorState
  ) {
    LoadingStore.setLoading(true)

    const newTitle = JSON.stringify(
      convertToRaw(paragraphTitle.getCurrentContent())
    )
    const newDescription = JSON.stringify(
      convertToRaw(paragraphDescription.getCurrentContent())
    )
    const currentParagraph = this.getCurrentFaqParagraph()

    const response = await api.put(
      `api/FAQ/editParagraph`,
      {
        Title: newTitle,
        Description: newDescription,
        Position: currentParagraph?.Position || 0,
      },
      {
        params: {
          id: this.currentFaqParagraphId,
        },
      }
    )

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq?.map(e => {
      if (e?.Id === this.currentFaqChapterId) {
        return {
          ...e,
          Paragraphs: e?.Paragraphs?.map(p => {
            if (p?.Id === this.currentFaqParagraphId) {
              return {
                ...p,
                Title: newTitle,
                Description: newDescription,
              }
            }

            return p
          }),
        }
      }

      return e
    })
  }

  async moveParagraph(
    chapterId: string,
    newArray: Array<{ id: string; index: number }>
  ) {
    LoadingStore.setLoading(true)

    const response = await api.put(
      `api/FAQ/editListOfParagraphs`,
      newArray?.map(e => ({
        Id: e?.id,
        Position: e?.index,
      }))
    )

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq?.map(e => {
      if (e?.Id === chapterId) {
        return {
          ...e,
          Paragraphs: e?.Paragraphs?.map(paragraph => {
            const arr = newArray.find((e: any) => {
              return e?.id === String(paragraph?.Id)
            })

            const newIndex: number = arr?.index || 0

            return {
              ...paragraph,
              Position: newIndex,
            }
          }).sort((a, b) => a.Position - b.Position),
        }
      }

      return e
    })
  }

  async deleteParagraph(paragraphId: string) {
    LoadingStore.setLoading(true)

    const response = await api.delete(`api/FAQ/deleteParagraph`, {
      params: {
        id: paragraphId,
      },
    })

    LoadingStore.setLoading(false)

    if (response.status >= 400)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    this.faq = this.faq?.map(e => {
      if (e?.Id === this.currentFaqChapterId) {
        return {
          ...e,
          Paragraphs: e?.Paragraphs?.filter(p => p?.Id !== paragraphId),
        }
      }

      return e
    })
  }
}

export default new FAQ()
