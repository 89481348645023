export const clickWhitepaper = () => {
  const win = window.open('https://authencity.io/files/Authencity_White_Paper.pdf', '_blank')
  if (win) {
    win.focus()
  }
}

export const clickCookies = () => {
  const win = window.open(
    'https://authencity.io/files/Cookies_Policy_Authencity.pdf',
    '_blank'
  )
  if (win) {
    win.focus()
  }
}

export const clickTermsAndConditions = () => {
  const win = window.open('https://authencity.io/files/Authencity_Terms_and_Conditions.pdf', '_blank')
  if (win) {
    win.focus()
  }
}

export const getTermsAndConditionsLink = () => {
  return 'https://authencity.io/files/Authencity_Terms_and_Conditions.pdf'
}

export const clickUserPolicy = () => {
  const win = window.open('https://authencity.io/files/Authencity_User_Policy.pdf', '_blank')
  if (win) {
    win.focus()
  }
}

export const clickDMCAPolicy = () => {
  const win = window.open('https://authencity.io/files/DMCA_Policy_Authencity.pdf', '_blank')
  if (win) {
    win.focus()
  }
}

export const getHowToEarnAuthTokensText = () => {
  return 'AUTH is the ERC-20 utility token that fuels the Authencity platform. It can be earned and used on the platform without the need to claim it. If you do want to withdraw AUTH to your wallet, simply claim your AUTH on this page.'
}