import { arrayMove } from '@dnd-kit/sortable'

export const changeDnDOrder = (
  event: any,
  items: Array<any>
):
  | {
      newArray: Array<any>
      newArraySorted: Array<any>
      active: { id: string; index: number }
      over: { id: string; index: number }
    }
  | undefined => {
  const { active, over } = event

  if (active?.id !== over?.id) {
    const activeIndex = items?.map(e => e?.id).indexOf(active?.id)
    const overIndex = items?.map(e => e?.id).indexOf(over?.id)

    if (activeIndex >= 0 && overIndex >= 0) {
      const newArray = arrayMove(items, activeIndex, overIndex)

      return {
        newArray,
        newArraySorted: newArray.map((e, i) => ({ id: e.id, index: i + 1 })),
        active: { id: active.id, index: activeIndex },
        over: { id: over.id, index: overIndex },
      }
    }
  }

  return undefined
}
