import { makeAutoObservable } from 'mobx'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import EthereumProvider from '@walletconnect/ethereum-provider'
import { WalletClient } from 'wagmi'
import { ethers } from 'ethers'


class WalletConnect {
  connector: WalletConnectConnector | null = null
  provider: EthereumProvider | null = null
  address: string = ''
  chainId: number | null = null
  connected: boolean = false
  walletClient: WalletClient | null = null
  openWeb3modal: any = null
  disconnectWalletConnect: any = null


  constructor() {
    makeAutoObservable(this)
  }

  setConnector(value: WalletConnectConnector | null) {
    this.connector = value
  }

  setProvider(value: EthereumProvider | null) {
    this.provider = value
  }

  setAddress(value: string) {
    this.address = value
  }

  setChainId(value: number) {
    this.chainId = value
  }

  setConnected(value: boolean) {
    this.connected = value
  }

  setWalletClient(value: WalletClient | null) {
    this.walletClient = value
  }

  setFnOpenWeb3modal(fn: (options?: any) => Promise<any>) {
    this.openWeb3modal = fn
  }

  setFnDisconnectWalletConnect(fn: any) {
    this.disconnectWalletConnect = fn
  }

  // Set in useWalletConnect web3Provider and Signer
  getWeb3ProviderAndSigner = async () => {
    const provider = this.provider
    await provider?.enable()
    const web3Provider = new ethers.BrowserProvider(provider as EthereumProvider)
    const signer = await web3Provider.getSigner()

    return ({ web3Provider, signer })

    // make like this
    // await this.provider?.enable()
    // return ({ this.web3Provider, this.signer })
  }
}

export default new WalletConnect()
