import React from 'react'

import { observer } from 'mobx-react'

import { useStore } from 'store'

import { ReactComponent as LoaderInner } from 'sources/images/loader/loaderInner.svg'
import { ReactComponent as LoaderOuter } from 'sources/images/loader/loaderOuter.svg'

import { Dots } from '../Dots'

import styles from './styles.module.scss'

interface IProps {
  active?: boolean
}

const Loading: React.FC<IProps> = observer(({ active }) => {
  const { loading } = useStore()

  return (
    <>
      {(loading.active || active) && (
        <div className={styles.loading}>
          <div className={styles.loaderBox}>
            <LoaderOuter className={styles.loaderOuter} />
            <LoaderInner className={styles.loaderInner} />
          </div>
          {loading.loadingType === 'upload' ? (
            <div className={styles.textBox}>
              <div className={styles.text}>
                Uploaded video{loading.loadingProgress < 100 ? <Dots /> : null}
                <div style={{ marginLeft: '10px' }}>
                  {loading.loadingProgress}%
                </div>
              </div>
              {loading.loadingProgress >= 100 ? (
                <div className={styles.saving}>
                  Converting
                  <Dots />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
})

Loading.displayName = 'Loading'

export default Loading
