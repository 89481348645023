import { action, makeAutoObservable, observable } from 'mobx'
import type {
  IBackingFeed,
  ILoadPage,
  IPublication,
  IUser,
  IUserFeed,
} from '../models'
import { api, defaultLoadPage, TabTypeLiveFeed } from '../utils'

class LiveFeed {
  tabTypeLiveFeed: TabTypeLiveFeed = TabTypeLiveFeed.newPosts

  publications: IPublication[] = []
  profiles: IUserFeed[] = []
  backing: IBackingFeed[] = []

  scrollPositionPublications: number = 0
  scrollPositionPublicationsWindow: number = 0
  scrollPositionProfiles: number = 0
  scrollPositionProfilesWindow: number = 0
  scrollPositionBacking: number = 0
  scrollPositionBackingWindow: number = 0

  pageSettingsPublications: ILoadPage = defaultLoadPage
  startTotalItemsPublications: number = 0

  pageSettingsProfiles: ILoadPage = defaultLoadPage
  startTotalItemsProfiles: number = 0

  pageSettingsBacking: ILoadPage = defaultLoadPage
  startTotalItemsBacking: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setTabTypeLiveFeed(value: TabTypeLiveFeed) {
    this.tabTypeLiveFeed = value
  }

  //Publications

  setPublications(value: IPublication[]) {
    this.publications = value
  }

  setScrollPositionPublications(value: number) {
    this.scrollPositionPublications = value
  }

  setScrollPositionPublicationsWindow(value: number) {
    this.scrollPositionPublicationsWindow = value
  }

  setPageSettingsPublications(value: ILoadPage) {
    this.pageSettingsPublications = value
  }

  setStartTotalItemsPublications(value: number) {
    this.startTotalItemsPublications = value
  }

  //Profiles

  setProfiles(value: IUserFeed[]) {
    this.profiles = value
  }

  setScrollPositionProfiles(value: number) {
    this.scrollPositionProfiles = value
  }

  setScrollPositionProfilesWindow(value: number) {
    this.scrollPositionProfilesWindow = value
  }

  setPageSettingsProfiles(value: ILoadPage) {
    this.pageSettingsProfiles = value
  }

  setStartTotalItemsProfiles(value: number) {
    this.startTotalItemsProfiles = value
  }

  //Backing

  setBacking(value: IBackingFeed[]) {
    this.backing = value
  }

  setScrollPositionBacking(value: number) {
    this.scrollPositionBacking = value
  }

  setScrollPositionBackingWindow(value: number) {
    this.scrollPositionBackingWindow = value
  }

  setPageSettingsBacking(value: ILoadPage) {
    this.pageSettingsBacking = value
  }

  setStartTotalItemsBacking(value: number) {
    this.startTotalItemsBacking = value
  }

  async getPublications(page: ILoadPage, startTotalItems: number) {
    const response = await api.get(`api/Publication/GetAllUserPublications`, {
      params: {
        startTotalItems,
        page: page.PageNumber,
        pageSize: page.PageSize,
        sortBy: 0,
        orderByDescending: true,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getProfiles(page: ILoadPage, startTotalItem: number) {
    const response = await api.get(`api/Account/getNewProfiles`, {
      params: {
        startTotalItem,
        pageNumber: page.PageNumber,
        pageSize: 20,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getBacking(page: ILoadPage, startTotalItem: number) {
    const response = await api.get(`api/Donate/getNewBackingInfo`, {
      params: {
        startTotalItem,
        pageNumber: page.PageNumber,
        pageSize: 20,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }
}

export default new LiveFeed()
