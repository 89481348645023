import { observable, action, makeAutoObservable } from 'mobx'
import type { ILoadPage, IPublication } from 'models'
import { api, defaultLoadPage, TradePostsType, TradingTabType } from 'utils'

class PageActivities {
  tradingPublications: IPublication[] = []
  tradingTab: TradingTabType = TradingTabType.allWithBet
  tradingPageSettings: ILoadPage = defaultLoadPage
  tradingStartTotalItems: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  
  setTradingPublications = (value: IPublication[]) => {
    this.tradingPublications = value
  }

  
  setTradingTab(value: TradingTabType) {
    this.tradingTab = value
  }
  
  setTradingPageSettings(value: ILoadPage) {
    this.tradingPageSettings = value
  }
  
  setTradingStartTotalItems(value: number) {
    this.tradingStartTotalItems = value
  }

  
  async getTradingPosts(type: TradePostsType, paramPage: ILoadPage, startTotalItems: number) {
    const response = await api.get(`api/Publication/getTradingPosts`, {
      params: {
        startTotalItems,
        pageSize: 10,
        pageNumber: paramPage.PageNumber,
        type
      }
    })

    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  async getApprovedAmount(wallet: string) {
    const response = await api.get(`api/Account/getApprovedAmount?wallet=${wallet}`)

    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }

  
  async updateApprovedAmount(amount: number) {
    const response = await api.put(`api/Account/updateApprovedAmount?amount=${amount}`)

    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error',
      )
    return response.data
  }
}

export default new PageActivities()
