import { observable, action, makeAutoObservable } from 'mobx'
import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr/dist/esm/HubConnection'

class SignalR {
	connection: signalR.HubConnection | null = null
	status: HubConnectionState = HubConnectionState.Disconnected


	constructor() {
		makeAutoObservable(this)
	}


	setConnection(value: signalR.HubConnection) {
		this.connection = value
	}

	setConnectionStatus(value: HubConnectionState) {
		this.status = value
	}
}

export default new SignalR()
