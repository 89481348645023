import { useEffect } from 'react'

export const useBodyDisableScroll = (value: boolean, disable?: boolean) => {
  useEffect(() => {
    if (!disable) {
      document.body.style.overflow = value ? 'hidden' : 'unset'
    }

    return () => {
      if (!disable) {
        document.body.style.overflow = 'unset'
      }
    }
  }, [value, disable])
}
