import { action, makeAutoObservable } from 'mobx'
import { api } from 'utils/config'
import { IStreamUpdateOptions } from 'models'
import { activeTabType, getEditorStateAsString } from 'utils'
import { EditorState } from 'draft-js'

class VideoStreamStore {
  value: string = ''


  constructor() {
    makeAutoObservable(this)
  }


  setValue(value: string) {
    this.value = value
  }

  async prepareToMint(draftId: string) {
    const response = await api.post(`api/LiveStream/prepareToMint`, null,
      {
        params: {
          draftId
        }
      })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async getStreamOptions() {
    const response = await api.get(`api/LiveStream/getStreamOptions`)
    if (response.status === 204) return null

    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async regenerateStreamKey() {
    const response = await api.get(`api/LiveStream/regenerateStreamKey`)
    if (response.status === 204) return null

    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }


  async getStreamInfoByNickname(nickname: string) {
    const response = await api.get(`api/LiveStream/getStreamInfoByNickname`,
      {
        params: {
          nickname
        }
      }
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async getAllStreams() {
    const response = await api.get(`api/LiveStream/getAllStreams`)
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async updateStreamOptions({ title, tags, categoryId, twitterNotification, description }: IStreamUpdateOptions) {
    const response = await api.put(`api/LiveStream/updateStreamOptions`, null, {
      params: {
        title: title ? getEditorStateAsString(title) : null,
        description: description ? getEditorStateAsString(description) : null,
        tags: tags,
        categoryId: categoryId ? categoryId : null,
        twitterNotification: twitterNotification ? getEditorStateAsString(twitterNotification) : null
      }
    })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async endLiveStreamDraft(playbackId: string) {
    const response = await api.post(`api/LiveStream/endStream`, null,
      {
        params: {
          playbackId
        }
      }
    )
    if (response.status !== 201)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async addLikeToStream(muxStreamId: string) {
    const response = await api.post(`api/LiveStream/addLikeToStream`, null,
      {
        params: {
          muxStreamId
        }
      })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

  async backStream(muxStreamId: string, amount: number, message: EditorState) {
    const response = await api.post(`api/LiveStream/backStream`, null,
      {
        params: {
          muxStreamId,
          amount,
          message: getEditorStateAsString(message)
        }
      })
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))

    return response.data
  }

}

export default new VideoStreamStore()
