import { AuthCurrency, EthereumChainId, PolygonChainId } from '../const'

export const getChainIdFromNetworkType = (type: number) => {
  if (type === 0) {
    return EthereumChainId
  } else if (type === 1) {
    return PolygonChainId
  }
}

export const getNetworkTypeFromChainId = (chainId: number) => {
  if (chainId === EthereumChainId) {
    return 0
  } else if (chainId === PolygonChainId) {
    return 1
  }
}

export const getChainIdFromPaymentCurrency = (currency: AuthCurrency) => {
  if (currency === AuthCurrency.ethereum) {
    return EthereumChainId
  } else if (currency === AuthCurrency.polygon) {
    return PolygonChainId
  }
}
// getNetworkType
export const getNetworkTypeFromPaymentCurrency = (currency: AuthCurrency) => {
  if (currency === AuthCurrency.ethereum) {
    return 0
  } else {
    return 1
  }
}


export const getTransactionLink = (NetworkType: number, PublicationKey: string, BlockchainId: number) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
    if (NetworkType === 0) {
      return `${process.env.REACT_APP_TRANSACTION_LINK_ETHERSCAN}${process.env.REACT_APP_ERC721_ADDRESS_OBJECT}/${BlockchainId}`
    } else {
      return `${process.env.REACT_APP_TRANSACTION_LINK_POLYGONSCAN}${process.env.REACT_APP_ERC721_ADDRESS_OBJECT_MATIC}?a=${BlockchainId}`
    }
  } else {
    if (NetworkType === 0) {
      return `${process.env.REACT_APP_TRANSACTION_LINK_ETHERSCAN}${PublicationKey}`
    } else {
      return `${process.env.REACT_APP_TRANSACTION_LINK_POLYGONSCAN}${PublicationKey}`
    }
  }
}
