import { observable, action, makeAutoObservable } from 'mobx'
import { api } from '../utils'

class Twitter {
  constructor() {
    makeAutoObservable(this)
  }


  async saveTwitterId(oauth_token: string, oauth_verifier: string) {
    const response = await api.get(
      `api/Twitter/saveTwitterId?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }

  async getListTwitterUserBackingInfo(nickname: string) {
    const response = await api.get(`api/Twitter/getListTwitterUserBackingInfo`, {
        params: {
          nickname
        }
      }
    )

    if (response.status !== 200) {
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))
    }

    return response.data
  }

  async prepareTwitterInfo(twitternickname: string) {
    const response = await api.get(`api/Twitter/prepareTwitterInfo`, {
        params: {
          user: twitternickname
        }
      }
    )

    if (response.status !== 200) {
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))
    }

    return response.data
  }

  async getTwitterUserBackingAmount(nickname: string) {
    const response = await api.get(
      `api/Twitter/getTwitterBackingAmount?nickname=${nickname}`
    )
    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }

  async subscribeToAutomaticMessages(oauth_token: string, oauth_verifier: string) {
    const response = await api.get(`api/Twitter/unfollowTwitter`, {
      params: { oauth_token, oauth_verifier }
    })

    if (response.status !== 200)
      throw new Error(response.data && (response.data.Description || response.data.Title || 'Some error'))

    return response.data
  }
}

export default new Twitter()
