import { format } from 'date-fns'
import moment from 'moment'

export const getDate = (date: string) => moment(date).format('DD.MM.YYYY')
export const getTime = (date: string) =>
  moment(moment.utc(date).toDate()).local().format('HH:mm')
export const getTimeAgo = (date: string) =>
  moment(moment.utc(date).toDate()).local().fromNow()
// export const getServerTime = (date: string) => moment.utc(date).local().format('YYYY-MM-DDTHH:mm:SS.sss');

// date-fns
const formatDate = format(new Date(), 'HH:mm dd.MM.yyyy')

export const getTimeFromSeconds = (
  secs: number,
  format: 'ceil' | 'withZero' = 'ceil'
) => {
  let hr: string | number = Math.floor(secs / 3600)
  let min: string | number = Math.floor((secs - hr * 3600) / 60)
  let sec: string | number = Math.floor(secs - hr * 3600 - min * 60)

  if (min < 10) {
    if (format === 'withZero') {
      min = '0' + min
    }
  }

  if (sec < 10) {
    if (format === 'withZero') {
      sec = '0' + sec
    }
  }

  return { hr, min, sec }
}

export const getVideoDuration = (secs: number) => {
  if (isNaN(secs) || secs === 0) return ''
  let hr: string | number = Math.floor(secs / 3600)
  let min: string | number = Math.floor((secs - hr * 3600) / 60)
  let sec: string | number = Math.floor(secs - hr * 3600 - min * 60)

  let duration = ''
  duration += sec < 10 ? '0' + sec : sec

  if (min < 10 && hr > 0) {
    duration = '0' + min + ':' + duration
  } else {
    duration = min + ':' + duration
  }

  if (hr > 0) {
    duration = hr + ':' + duration
  }

  return duration
}

export const playerTimeFormat = (seconds: any) => {
  if (isNaN(seconds)) {
    return '00:00'
  }

  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds().toString().padStart(2, '0')

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`
  } else {
    return `${mm}:${ss}`
  }
}

const ONE_DAY = 60 * 60 * 24
const ONE_HOUR = 60 * 60
const ONE_MINUTE = 60

export const getRemainingTime = (diffInSeconds: number) => {
  if (diffInSeconds <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 }
  }

  const days = Math.floor(diffInSeconds / ONE_DAY)
  const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR)
  const minutes = Math.floor(
    (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
  )
  const seconds =
    diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE
  return { days, hours, minutes, seconds }
}

export const formatDateWithFullMonth = (date: string) => {
  return format(new Date(date), 'dd LLLL yyyy')
}

export const formatDateDateMonthYear = (date: string) => {
  return format(new Date(date), 'dd.MM.yyyy')
}

export const formatDateHoursMinutes = (date: string) => {
  return format(new Date(date), 'HH:mm')
}
