import { useEffect } from 'react'

export const useOutsideClick = (ref: any, callback: () => void) => {

  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    if (!ref.current) return
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  } ,[ref.current])
}
