import { useCallback, useEffect, useRef, useState } from 'react'

export const useStateWithCallBack = <S>(initialState: S) => {
  const [state, setState] = useState(initialState)
  const callbackRef = useRef<((state: S) => void) | null>(null)

  const updateState = useCallback((newState: S | ((prevState: S) => S), callback?: (state: S) => void) => {
    callbackRef.current = callback ? callback : null

    setState(prev => newState instanceof Function ? newState(prev) : newState)
  }, [])

  // const updateState = useCallback((newState: S, callback: (state: S) => void) => {
  //   if (callbackRef.current) {
  //     callbackRef.current = callback
  //   }
  //
  //   setState(newState)
  // }, [])


  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state)
      callbackRef.current = null
    }
  }, [state])

  return [state, updateState] as const
}
