import { ethers } from 'ethers'
import { toast } from 'App'
import LoadingStore from 'store/loading'
import WalletConnectStore from 'store/walletConnect'
import ProfileStore from 'store/profile'
import { ABI_ERC20_GOERLI, EthereumChainId, LoginProvider } from '../const'

const { ethereum } = window

export const ContractDecimal = 8

export const getAuthencityObjectERC20 = async () => {
  if (ProfileStore.getLoginProvider() === LoginProvider.Metamask && ethereum) {
    return await getMMAuthencityObjectERC20()
  }

  if (ProfileStore.getLoginProvider() === LoginProvider.WalletConnect && WalletConnectStore.provider?.connected) {
    return await getWCAuthencityObjectERC20()
  }

  return null
}

export const getMMAuthencityObjectERC20 = async () => {
  const provider = new ethers.BrowserProvider(ethereum)
  const signer = await provider.getSigner()

// 	const chainId = await ethereum.request({method: 'eth_chainId'})
  const { chainId } = await provider.getNetwork()
  if (Number(chainId) === EthereumChainId) {
    return new ethers.Contract(
      process.env.REACT_APP_ERC20_ADDRESS_OBJECT ?? '',
      ABI_ERC20_GOERLI,
      signer
    )
  } else {
    LoadingStore.setLoading(false)
    toast({
      type: 'warning',
      message: 'Change your network to Ethereum'
    })
    return null
  }
}

export const getWCAuthencityObjectERC20 = async () => {
  const { signer } = await WalletConnectStore.getWeb3ProviderAndSigner()

  const chainId = WalletConnectStore.provider?.chainId
  if (chainId === EthereumChainId) {
    return new ethers.Contract(
      process.env.REACT_APP_ERC20_ADDRESS_OBJECT ?? '',
      ABI_ERC20_GOERLI,
      signer
    )
  } else {
    LoadingStore.setLoading(false)
    toast({
      type: 'warning',
      message: 'Change your network to Ethereum'
    })
    return null
  }
}

export const approveAmount = async (approveAmount: number, onSuccess: (amount: number) => Promise<void>, onError: (message?: string) => void) => {

  const spender = process.env.REACT_APP_APPROVE_ADDRESS
  const amount = convertNumber(approveAmount)
  const ObjectERC20 = await getMMAuthencityObjectERC20()
  if (ObjectERC20) {
    ObjectERC20.approve(spender, amount)
      .then(async (res: any) => {
        await onSuccess(approveAmount)
      })
      .catch((e: any) => {
        console.log('ObjectERC20.approve', e)
        e.code === 4001 ? onError(e.message) : onError()
      })
  }
}


export const convertNumber = (amount: number) => {
  const padEndZeros = (num: string, size: number) => {
    let s = num + ''
    while (s.length < size) s = s + '0'
    return s
  }

  if (Number.isInteger(amount)) {
    return `${amount}00000000`
  }
  const [beforeDot, afterDot] = String(amount).split('.')
  const amountAfterDot = padEndZeros(afterDot, 8)
  return beforeDot + amountAfterDot
}

//Call permission
// ethereum.request({
// 	method: 'wallet_requestPermissions',
// 	params: [{ eth_accounts: {} }],
// })
