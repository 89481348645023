import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useStore } from '../../store'

export const useRestoreWidgetWindow = () => {
  const { widget } = useStore()

  const navigate = useNavigate()

  useEffect(() => {
    if (widget.pathname) {
      navigate(widget.pathname)
    }
  }, [widget.pathname])
}
