import React, { CSSProperties, FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import { useAuth } from '../../utils/hooks'
import { LoginProvider } from 'utils'

import ButtonNew from 'components/ButtonNew'
import Modal from 'components/Modal'
import { ModalPWA } from 'componentsPWA/ModalPWA'

import { useStore } from 'store'

import { ReactComponent as Apple } from 'sources/images/apple.svg'
import Google from 'sources/images/googleColor.svg'
import Metamask from 'sources/images/mm_logo.svg'
import { ReactComponent as Twitter } from 'sources/images/twitter-x.svg'
import { ReactComponent as WalletConnect } from 'sources/images/walletConnect.svg'

import styles from './styles.module.scss'

const loginTypes = [
  {
    type: LoginProvider.Google,
    icon: <img src={Google} alt="google" className={styles.typeIcon} />,
  },
  {
    type: LoginProvider.Apple,
    icon: (
      <Apple
        className={styles.typeIcon}
        style={{ width: '21px', minWidth: '21px' }}
      />
    ),
  },
  {
    type: LoginProvider.Twitter,
    icon: <Twitter className={cn(styles.typeIcon, styles.fillIcon)} />,
  },
  {
    type: LoginProvider.Metamask,
    icon: <img src={Metamask} alt="metamask" className={styles.typeIcon} />,
  },
  {
    type: LoginProvider.WalletConnect,
    icon: <WalletConnect className={cn(styles.typeIcon, styles.fillIcon)} />,
  },
]

const connectTypes = [
  {
    type: LoginProvider.Metamask,
    icon: <img src={Metamask} alt="metamask" className={styles.typeIcon} />,
  },
  {
    type: LoginProvider.WalletConnect,
    icon: <WalletConnect className={cn(styles.typeIcon, styles.fillIcon)} />,
  },
]

interface IProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  type?: 'logIn' | 'connect'
  connectedWallet?: string
}

//Resize
export const LogInModal: FC<IProps> = observer(
  ({ isOpen, setIsOpen, type = 'logIn', connectedWallet }) => {
    const { mediaQuery, main: mainStore } = useStore()

    const closeLoginModal = () => {
      setIsOpen(false)
    }

    const {
      handleGoogleLogin,
      handleAppleLogin,
      handleTwitterLogin,
      handleMetamaskLogin,
      handleWalletConnectLogin,
    } = useAuth({
      type,
      connectedWallet,
      closeLoginModal,
    })

    const onClickLogin = (type: LoginProvider) => {
      if (type === LoginProvider.Google) {
        handleGoogleLogin()
      }

      if (type === LoginProvider.Apple) {
        handleAppleLogin()
      }

      if (type === LoginProvider.Twitter) {
        handleTwitterLogin()
      }

      if (type === LoginProvider.Metamask) {
        handleMetamaskLogin()
      }

      if (type === LoginProvider.WalletConnect) {
        handleWalletConnectLogin()
      }
    }

    const types = type === 'connect' ? connectTypes : loginTypes
    const title = type === 'connect' ? 'Connect' : 'Log in with'

    const modalContent = (containerStyle?: CSSProperties) => (
      <div className={styles.container} style={containerStyle}>
        <div className={styles.title}>{title}</div>
        <div className={styles.loginTypes}>
          {types.map(item => (
            <ButtonNew
              key={item.type}
              onClick={() => onClickLogin(item.type)}
              btnStyleType="style_6"
              btnStyle={{
                height: mediaQuery.minWidth_2200 ? '60px' : '50px',
                width: '100%',
                boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.05)',
              }}
              btnText={
                <>
                  {item.icon}
                  {item.type}
                </>
              }
            />
          ))}
        </div>
      </div>
    )

    return (
      <>
        {mainStore.isPWA ? (
          <ModalPWA
            open={isOpen}
            onClose={closeLoginModal}
            closeButton
            disableOutsideClick
            // fullScreen
            paperSX={{ justifyContent: 'center' }}
          >
            {modalContent()}
          </ModalPWA>
        ) : (
          <Modal
            size="responsive"
            contentStyle={{ maxWidth: 'calc(min(380px, 95vw))' }}
            onClose={closeLoginModal}
            open={isOpen}
            closeButton
            closeButtonStyle={{ left: 'unset', right: '15px' }}
            blockOutsideClose
            withAnimation
          >
            {modalContent({ padding: '32px' })}
          </Modal>
        )}
      </>
    )
  }
)

LogInModal.displayName = 'LogInModal'
