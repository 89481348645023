import { action, makeAutoObservable } from 'mobx'
import { api } from 'utils/config'
import type { ICreateComment } from 'models/comments'
import {
  IActiveHashtag,
  IPublication,
  IUserVideosPageData,
  IVideosPageData,
} from 'models'
import PublicationStore from 'store/publication'
import ProfileStore from 'store/profile'
import { convertToRaw } from 'draft-js'
import CreatePostStore from 'store/createPost'
import TopStore from 'store/top'
import LiveFeedStore from 'store/liveFeed'
import { activeTabType } from 'utils'

class VideoStore {
  videosPageData: IVideosPageData | null = null
  userVideosPageData: IUserVideosPageData | null = null
  isClickBackToVideos: boolean = false
  scrollPosition: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  setVideosPageData(value: IVideosPageData | null) {
    this.videosPageData = value
  }

  setUserVideosPageData(value: IUserVideosPageData | null) {
    this.userVideosPageData = value
  }

  setScrollPosition(value: number) {
    this.scrollPosition = value
  }

  setIsClickBackToVideos(value: boolean) {
    this.isClickBackToVideos = value
  }

  async getRecommendationsVideos(
    videoId: number,
    pageNumber: number,
    type: 'videos' | 'shorts' = 'videos'
  ) {
    const path =
      type === 'videos' ? 'getRecommendations' : 'getShortsRecommendations'
    const response = await api.get(`api/VideoLibrary/${path}`, {
      params: {
        videoId,
        pageNumber,
        pageSize: 20,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getVideos(
    path: string,
    pageNumber: number,
    filter: number,
    searchText: string = ''
  ) {
    const response = await api.get(`api/VideoLibrary/${path}`, {
      params: {
        filter,
        pageNumber,
        pageSize: 20,
        searchText,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getUserVideos(
    pageNumber: number,
    filter: number,
    searchText: string,
    nickname: string = '',
    sorting: 0 | 1 | 2 = 0
  ) {
    const response = await api.get(`api/VideoLibrary/getUserVideos`, {
      params: {
        pageNumber,
        pageSize: 20,
        filter,
        sorting,
        searchText,
        nickname,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getLibraryVideos() {
    const response = await api.get(`api/VideoLibrary/getLibrary`)

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getVideoInfo(videoId: number) {
    const response = await api.get(
      `api/VideoLibrary/getVideoInfo?videoId=${videoId}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async getThreadPublications(threadId: number) {
    const response = await api.get(`api/Publication/GetThreadPublications`, {
      params: {
        threadId,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async addDeleteLike(LikeObjectId: number, BlockchainId: number) {
    const response = await api.post(`api/Like/CreateLikePublication`, {
      BlockchainId,
      LikeObjectId,
    })

    if (response.status === 200 || response.status === 204) {
      return response.data
    } else {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
  }

  async addDeleteLikeComment(LikeObjectId: number, BlockchainId: number) {
    const response = await api.post(`api/Like/CreateLikeComment`, {
      BlockchainId,
      LikeObjectId,
    })

    if (response.status === 200 || response.status === 204) {
      return response.data
    } else {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
  }

  async addInFavorite(publicationId: number) {
    const response = await api.post(
      `/api/Publication/AddFavoritePublication`,
      null,
      {
        params: {
          publicationId,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async deleteFromFavorite(publicationId: number) {
    const response = await api.delete(
      `/api/Publication/RemoveFavoritePublication`,
      {
        params: {
          publicationId,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async addPostInFavorite(publicationId: number, isSinglePost?: boolean) {
    const updatedPosts = (posts: IPublication[]) => {
      return posts.map(item =>
        item.Id === publicationId ? { ...item, Favorite: true } : item
      )
    }
    if (!ProfileStore.isAuthorizedProfileToast()) return
    try {
      await this.addInFavorite(Number(publicationId))

      this.updateAllPosts(updatedPosts)
    } catch (e) {}
  }

  async deletePostFromFavorite(
    publicationId: number,
    activeTab: string,
    isSinglePost?: boolean
  ) {
    const updatedPosts = (posts: IPublication[]) => {
      return posts.map(item =>
        item.Id === publicationId ? { ...item, Favorite: false } : item
      )
    }

    if (!ProfileStore.isAuthorizedProfileToast()) return
    try {
      await this.deleteFromFavorite(publicationId)

      if (activeTab === activeTabType.favorites) {
        PublicationStore.setPublication([])
      }

      this.updateAllPosts(updatedPosts)
    } catch (e) {}
  }

  updateAllPosts(updateFunc: (posts: IPublication[]) => IPublication[]) {
    PublicationStore.setPublication(
      updateFunc(PublicationStore.publicationList)
    )
    PublicationStore.setThreadPublications(
      updateFunc(PublicationStore.threadPublications)
    )
    PublicationStore.setHashtagPublications(
      updateFunc(PublicationStore.hashtagPublications)
    )
    LiveFeedStore.setPublications(updateFunc(LiveFeedStore.publications))
    TopStore.setPublications(updateFunc(TopStore.publications))
  }

  async addToWatchLater(videoId: number) {
    const response = await api.post(`/api/VideoLibrary/addToWatchLater`, null, {
      params: {
        videoId,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async removeFromWatchLater(videoId: number) {
    const response = await api.delete(
      `/api/VideoLibrary/removeFromWatchLater`,
      {
        params: {
          videoId,
        },
      }
    )

    return response.data
  }

  async getVideosByHashtag(hashtag: IActiveHashtag, pageNumber: number) {
    const response = await api.get(`api/VideoLibrary/searchByTopHashtags`, {
      params: {
        pageNumber,
        pageSize: 20,
        tagId: hashtag.Id,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getTopHashtags() {
    const response = await api.get(`api/VideoLibrary/getTopHashtags`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getUserVideoLibraryProfile(nickname?: string) {
    const response = await api.get(
      `api/VideoLibrary/getUserVideoLibraryProfile`,
      {
        params: {
          nickname,
        },
      }
    )

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }
}

export default new VideoStore()
