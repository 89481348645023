import React, { FC } from 'react';
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

import styles from './styles.module.scss'
import { SxProps } from '@mui/material'

interface IProps {
  onClose?: () => void
  sx?: SxProps
}
export const CloseIconButtonPWA: FC<IProps> = ({onClose, sx}) =>  {
  return (
    <IconButton color="inherit"
                aria-label={`close`}
                onClick={onClose}
                sx={{
                  borderRadius: '7px',
                  color: 'var(--mainColor)',
                  width: '32px',
                  height: '32px',
                  backgroundColor: 'var(--mainColorRevert)',
                  boxShadow: '5px 5px 25px 0px rgba(0, 0, 0, 0.05)',
                  ...sx
                }}
    >
      <CloseIcon/>
    </IconButton>
  );
}
