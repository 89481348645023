import { observable, action, makeAutoObservable } from 'mobx'

class Theme {
    theme: string = 'light'


    constructor() {
        makeAutoObservable(this)
    }


    setTheme(value: string) {
        this.theme = value
    }
}

export default new Theme()
