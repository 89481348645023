import { useCallback, useEffect, useRef, useState } from 'react'

export const useGetElementSize = () => {
  const ref: any = useRef(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleResize = useCallback(() => {
    setWidth(ref?.current?.getBoundingClientRect()?.width)
    setHeight(ref?.current?.getBoundingClientRect()?.height)
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return { ref, width, height, resize: handleResize }
}
