import { useEffect, useRef, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import { HubConnectionState } from '@microsoft/signalr'
import { useStore } from 'store'

export const useSignalR = (selectedUserId: string | null) => {
  const { signalR: signalRStore, notification: notificationStore } = useStore()
  const isConnectedRef = useRef(false)

  useEffect(() => {
    if (isConnectedRef.current) {
      setTimeout(() => {
        buildConnectSignalR()
      }, 2000)
    } else {
      buildConnectSignalR()
    }

    return () => {
      // disconnectSignalR()
      disconnectSignalRBeforeunload()
    }
  }, [selectedUserId])

  useEffect(() => {
    window.addEventListener('beforeunload', disconnectSignalRBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', disconnectSignalRBeforeunload)
    }
  }, [])

  const disconnectSignalRBeforeunload = async () => {
    try {
      await disconnectSignalR()
      // added the delay otherwise database operation will not work
      for (let i = 0; i < 500000000; i++) {
      }
      console.log('beforeunload')
    } catch (e) {
      console.log('disconnectSignalRBeforeunload', e)
    }
  }

  const buildConnectSignalR = () => {
    try {
      const newConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_SIGNALR_URL}`)
        .withAutomaticReconnect()
        .withHubProtocol(new signalR.JsonHubProtocol())
        .configureLogging(signalR.LogLevel.Information)
        .configureLogging(signalR.LogLevel.Debug)
        .build()

      signalRStore.setConnection(newConnection)
      connectSignalR()
    } catch (e) {
      console.log(e)
    }
  }

  const connectSignalR = () => {
    try {
      if (signalRStore.connection) {
        console.log('start connecting............')
        signalRStore.connection.start()
          .then(async (result) => {
            if (signalRStore.connection) {
              try {
                console.log(`SignalR status:) ${signalRStore.connection?.state}`)
                console.log('connect selectedUserId', selectedUserId)
                console.log('connect signalR ID', signalRStore.connection.connectionId)
                signalRStore.setConnectionStatus(signalRStore.connection.state)
                isConnectedRef.current = true
                if (selectedUserId) {
                  await signalRStore.connection.invoke('OnConnectedAsync', selectedUserId, signalRStore.connection.connectionId)
                }
              } catch (e) {
                console.log(e)
              }
            }
          })
          .catch(e => {
            console.log('Connection SignalR failed: ', e)
            console.log('e.message', e.message)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const disconnectSignalR = async () => {
    try {
      if (signalRStore.connection) {
        console.log('disconnect selectedUserId', selectedUserId)
        console.log('disconnect signalR ID', signalRStore.connection.connectionId)
        await signalRStore.connection.invoke('OnDisconnectedAsync')
        signalRStore.connection.stop()
          .then(result => {
            console.log(`SignalR status:( ${signalRStore.connection?.state}`)
            signalRStore.setConnectionStatus(HubConnectionState.Disconnected)
            isConnectedRef.current = false
          })
          .catch(e => {
            console.log('Disconnect SignalR failed: ', e)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }
}

type SignalRReceiveMethods =
  'ReceivePublication'
  | 'ReceiveProfileInfo'
  | 'ReceiveBackingProfileInfo'
  | 'ReceiveBackingPostInfo'
  | 'ReceiveNotificationsCount'
  | 'ReceiveMessageToGroup'
  | 'ReceiveStreamLikesCount'
  | 'ReceiveStreamBacksCount'
  | 'ReceiveUsersOnStream'
  | 'ReceiveStreamTopBackers'
  | 'ReceiveChatBackingMessage'

export const useSignalRMethod = (method: SignalRReceiveMethods, signalRConnection: signalR.HubConnection | null, callback: (value: any) => void) => {
  useEffect(() => {
    if (signalRConnection) {
      try {
        signalRConnection.on(method, (value: any) => {
          callback(value)
        })
      } catch (e) {
        console.log(e)
      }
    }

    return () => {
      if (signalRConnection) {
        try {
          signalRConnection.off(method)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [signalRConnection])
}

export const useSignalRMethodAuth = (method: SignalRReceiveMethods, connection: signalR.HubConnection | null, status: HubConnectionState | undefined, callback: (value: any) => void, userId: string) => {
  useEffect(() => {
    // console.log('status.....:', status, 'userId........:', userId)
    if (connection && userId && status === HubConnectionState.Connected) {
      try {
        connection.on(method, (value: any) => {
          callback(value)
        })
      } catch (e) {
        console.log(e)
      }
    }

    return () => {
      if (connection) {
        try {
          connection.off(method)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [connection, status, userId])
}
