import React, { FC } from 'react';
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { CustomTooltip } from 'components/CustomTooltip'
import { useNavigate } from 'react-router-dom'

import { versions_link } from 'utils'
import packageInfo from '../../../../../package.json'

import authLogo from 'sources/images/auth.svg'
import authLogoDark from 'sources/images/authDark.svg'

import styles from './styles.module.scss'


interface IProps {}
export const Version: FC<IProps> = observer(props =>  {
  const {theme} = useStore()
  const navigate = useNavigate()

  const redirectToVersionsPage = () => {
    navigate(versions_link())
  }

  return (
   <div className={styles.versionContainer}>
     <img src={theme.theme === 'light' ? authLogo : authLogoDark}
       alt="logo"
       className={styles.logo}
     />
     <CustomTooltip title={'Click to see all versions'}
                    placement="right"
                    disablePortal
                    children={<div className={styles.version}
                                   onClick={redirectToVersionsPage}>v{packageInfo.version}</div>}/>
   </div>
  );
})
