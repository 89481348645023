import { action, makeAutoObservable } from 'mobx'
import { api } from 'utils/config'
import { IPublication } from 'models'
import { toast } from 'App'
import PublicationStore from 'store/publication'
import ProfileStore from 'store/profile'
import TopStore from 'store/top'
import LiveFeedStore from 'store/liveFeed'
import Marketplace from 'store/marketplace'
import { activeTabType } from 'utils'

class FavoriteStore {
  constructor() {
    makeAutoObservable(this)
  }

  async addInFavorite(publicationId: number) {
    const response = await api.post(
      `/api/Publication/AddFavoritePublication`,
      null,
      {
        params: {
          publicationId,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async deleteFromFavorite(publicationId: number) {
    const response = await api.delete(
      `/api/Publication/RemoveFavoritePublication`,
      {
        params: {
          publicationId,
        },
      }
    )
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async addPostInFavorite(publicationId: number, isSinglePost?: boolean) {
    const updatedPosts = (posts: IPublication[]) => {
      return posts.map(item =>
        item.Id === publicationId ? { ...item, Favorite: true } : item
      )
    }
    if (!ProfileStore.isAuthorizedProfileToast()) return
    try {
      await this.addInFavorite(Number(publicationId))

      this.updateAllPosts(updatedPosts)

      toast({
        type: 'success',
        message: `Post was successfully added to the favorites`,
      })
    } catch (e) {
      toast({
        type: 'error',
        message: `Post was not added to favorites`,
      })
    }
  }

  async deletePostFromFavorite(
    publicationId: number,
    activeTab: string,
    isSinglePost?: boolean
  ) {
    const updatedPosts = (posts: IPublication[]) => {
      return posts.map(item =>
        item.Id === publicationId ? { ...item, Favorite: false } : item
      )
    }

    if (!ProfileStore.isAuthorizedProfileToast()) return
    try {
      await this.deleteFromFavorite(publicationId)

      if (activeTab === activeTabType.favorites) {
        PublicationStore.setPublication([])
      }

      this.updateAllPosts(updatedPosts)

      toast({
        type: 'success',
        message: `Post was successfully deleted from favorites`,
      })
    } catch (e) {
      toast({
        type: 'error',
        message: `Post was not deleted from favorites`,
      })
    }
  }

  updateAllPosts(updateFunc: (posts: IPublication[]) => IPublication[]) {
    PublicationStore.setPublication(
      updateFunc(PublicationStore.publicationList)
    )
    PublicationStore.setThreadPublications(
      updateFunc(PublicationStore.threadPublications)
    )
    PublicationStore.setHashtagPublications(
      updateFunc(PublicationStore.hashtagPublications)
    )
    LiveFeedStore.setPublications(updateFunc(LiveFeedStore.publications))
    TopStore.setPublications(updateFunc(TopStore.publications))
    Marketplace.setTradingPublications(
      updateFunc(Marketplace.tradingPublications)
    )
  }
}

export default new FavoriteStore()
