import { useEffect, useState } from 'react'

export const useScrollPositionElement = (element: HTMLElement | null) => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(element?.scrollTop || 0)
    }
    element?.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => element?.removeEventListener('scroll', updatePosition)
  }, [element])

  return scrollPosition
}
