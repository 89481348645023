import { action, makeAutoObservable, observable } from 'mobx'
import {
  api
} from '../utils'
import LoadingStore from 'store/loading'
import { IPendingVideo } from 'models/pendingVideos'
import { ILoadPage } from '../models'


class PendingVideos {
  pendingVideos: IPendingVideo[] = []


  constructor() {
    makeAutoObservable(this)
  }

  
  setPendingVideos(value: IPendingVideo[]) {
    this.pendingVideos = value
  }

  
  async uploadVideo(formData: FormData, rangeParams: {} | { startPos: number, endPos: number }, videoTitle: string, videoDescription: string) {

    const response = await api.post(`api/Room/uploadVideo`, formData, {
      params: {
        ...rangeParams,
        videoTitle,
        videoDescription
      },
      onUploadProgress: function(progressEvent) {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          LoadingStore.setLoadingProgress(percentCompleted)
          console.log('percentCompleted:', percentCompleted)
        }
      }
    })

    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  
  async getProcessingVideoInfo() {
    const response = await api.get(`api/Room/getProcessingVideoInfo`)
    if (response.status !== 200) {
      throw new Error(response.data && (response.data.Description || response.data.Title || ''))
    }

    return response.data
  }

}

export default new PendingVideos()
