import mime from 'mime'

export const downloadTableExcel = (data: any, fileName?: string) => {
  const file = new Blob([data], {
    type: mime.getType('.xlsx') || '',
    // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const a = document.createElement('a')
  a.href = window.URL.createObjectURL(file)
  a.target = '_blank'
  a.download = `${fileName ?? 'index'}.xlsx`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
