export const clickDiscord = () => {
  const win = window.open('https://discord.com/invite/authencity', '_blank')
  if (win) {
    win.focus()
  }
}

export const getDiscordLink = () => {
  return 'https://discord.com/invite/authencity'
}

export const clickTelegram = () => {
  const win = window.open('https://t.me/authencity_io', '_blank')
  if (win) {
    win.focus()
  }
}

export const getTelegramLink = () => {
  return 'https://t.me/authencity_io'
}

export const clickTwitter = () => {
  const win = window.open('https://twitter.com/authencity_io', '_blank')
  if (win) {
    win.focus()
  }
}

export const getTwitterLink = () => {
  return 'https://twitter.com/authencity_io'
}
