import React, { CSSProperties, FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import styles from './styles.module.scss'

interface IProps {
  style?: CSSProperties
}
export const Dots: FC<IProps> = ({style}) =>  {
  return (
   <div className={styles.dotsBox} style={style}>
     <span className={styles.one}>.</span>
     <span className={styles.two}>.</span>
     <span className={styles.three}>.</span>
   </div>
  );
}
