import React, { FC, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { MenuList } from './MenuList'
import { Version } from './Version'
import { User } from './User'
import ButtonNew from 'components/ButtonNew'
import { LogInModal } from 'components/LogInModal'

import { registration_link } from 'utils'

interface IProps {
  open: boolean,
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void
}

export const Drawer: FC<IProps> = observer(({ open, toggleDrawer }) => {
  const { profile } = useStore()
  const navigate = useNavigate()
  const [isOpenLogInModal, setIsOpenLogInModal] = useState(false)

  const redirectToRegistrationPage = () => {
    navigate(registration_link())
  }

  const handleOpenLogInModal = () => {
    setIsOpenLogInModal(true)
  }

  const handleCloseLogInModal = () => {
    setIsOpenLogInModal(false)
  }

  const isAuthorizedProfile = profile.isAuthorizedProfile()

  return (
    <React.Fragment>
      <SwipeableDrawer
        disableSwipeToOpen
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'var(--backgroundColor)'
          }
        }}
      >
        <Box
          sx={{
            width: 250,
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <IconButton color="inherit"
                      aria-label={`close menu`}
                      onClick={toggleDrawer(false)}
                      sx={{
                        borderRadius: '7px',
                        color: 'var(--mainColor)',
                        width: '32px',
                        height: '32px',
                        marginTop: '24px',
                        marginLeft: '24px',
                        backgroundColor: 'var(--mainColorRevert)'
                      }}
          >
            <CloseIcon/>
          </IconButton>
          {isAuthorizedProfile
            ? <User/>
            : <Box sx={{ display: 'flex', margin: ' 30px 24px 20px 28px' }}>
              <ButtonNew
                onClick={redirectToRegistrationPage}
                btnStyleType="style_6"
                btnStyle={{
                  height: '40px',
                  width: '80px',
                  boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.05)',
                  marginRight: '15px'
                }}
                btnText={'Sign up'}
              />
              <ButtonNew
                onClick={handleOpenLogInModal}
                btnStyleType="style_8"
                btnStyle={{
                  height: '40px',
                  width: '80px',
                  boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.05)'
                }}
                btnText={'Log in'}
              />
            </Box>}
          <MenuList/>
          <Version/>
        </Box>
      </SwipeableDrawer>
      <LogInModal isOpen={isOpenLogInModal} setIsOpen={handleCloseLogInModal}/>
    </React.Fragment>
  )
})
