// import { io } from 'socket.io-client'
const { io } = require('socket.io-client')

const options = {
  forceNew: true,
  reconnectionAttempts: Infinity,
  timeout: 10000,
  transports: ['websocket'],
}

const socket = io('https://authencity.io', options)
// const socket = io.connect('http://localhost:3001', options)

// export default socket
module.exports = socket
