import {
  convertFromRaw,
  convertToRaw,
  DraftModel,
  EditorState,
  RawDraftContentState,
} from 'draft-js'
import { Block as EditorBlockType } from 'components/CustomEditor/util/constants'

export const getPostLengthFromEditorState = (editorData: EditorState) => {
  return editorData.getCurrentContent().getPlainText('\n').length
  // return editorData.getCurrentContent()
  //   .getBlocksAsArray()
  //   .reduce((prev, next, idx) => prev + next.getLength() + Number(idx === 0 ? 0 : 1), 0)
}

export const getPostTextFromEditorState = (editorData: EditorState) => {
  return editorData.getCurrentContent().getPlainText('\n')
  // return convertToRaw(editorData.getCurrentContent()).blocks.reduce((prev, item, idx) => idx === 0 ? prev + item.text : prev + '\n' + item.text, '')
}

export const getPostTextFromEditorStateIgnoreAtomicImageBlocks = (
  editorData: EditorState
) => {
  const rawData = convertToRaw(editorData.getCurrentContent())
  rawData.blocks = rawData.blocks.filter(
    block => block.type !== EditorBlockType.IMAGE
  )
  return EditorState.createWithContent(convertFromRaw(rawData))
    .getCurrentContent()
    .getPlainText('\n')
}

export const getPostLengthFromEditorStateRawBlocks = (
  stateBlocks: DraftModel.Encoding.RawDraftContentBlock[]
) => {
  return stateBlocks.reduce(
    (acc, next, idx) => acc + next.text.length + Number(idx === 0 ? 0 : 1),
    0
  )
}

export const getPostTextFromDataBaseText = (text: string) => {
  let parsedData: any
  try {
    parsedData = JSON.parse(text)
  } catch (e) {
    parsedData = text
  }
  if (typeof parsedData === 'string') {
    return parsedData
  }
  if (typeof parsedData === 'number') {
    return String(parsedData)
  }
  if (typeof parsedData === 'object' && parsedData !== null) {
    return (parsedData as RawDraftContentState).blocks.reduce(
      (prev, item, idx) =>
        idx === 0 ? prev + item.text : prev + '\n' + item.text,
      ''
    )
  }
}

export const getBlogpostReadTimeFromDataBaseText = (text: string) => {
  let parsedData: any
  let textLength = 0

  try {
    parsedData = JSON.parse(text)
  } catch (e) {
    parsedData = text
  }
  if (typeof parsedData === 'string') {
    textLength = parsedData.length
  }
  if (typeof parsedData === 'number') {
    textLength = String(parsedData).length
  }
  if (typeof parsedData === 'object' && parsedData !== null) {
    textLength = (parsedData as RawDraftContentState).blocks.reduce(
      (prev, item, idx) =>
        idx === 0 ? prev + item.text : prev + '\n' + item.text,
      ''
    ).length
  }

  return Math.round(textLength / 1250)
}

export const checkNumberOfLineBreaks = (value: string | undefined) => {
  if (value) {
    return value.match(/\n\n\n/)
  }
  return false
}

export const getCanDecryptNicknames = (data: EditorState): string[] => {
  const entityMap = convertToRaw(data.getCurrentContent()).entityMap
  const mentionArr = Object.keys(entityMap)
    .filter(idx => entityMap[idx].type === 'mention')
    .map(key => entityMap[key].data.mention.name)

  // delete splice, now it is for tagging only one user
  return mentionArr?.filter((e: string) => !!e)?.splice(0, 1)
}

export const getEditorStateAsString = (data: EditorState) => {
  if (typeof data === 'string') {
    return data
  } else {
    return JSON.stringify(convertToRaw(data.getCurrentContent()))
  }
}
