interface IResponseAPI {
  data: string | null | IResponseData
}

interface IResponseData {
  Description: string | undefined,
  Title: string | undefined,
}

export const apiError = (response: IResponseAPI, errorMessage?: string) => {
  if (response.data) {
    if (typeof response.data === 'string') {
      throw new Error(response.data)
    }

    if (response.data.Description || response.data.Title) {
      throw new Error(response.data.Description || response.data.Title)
    }

    throw new Error(errorMessage ? errorMessage : '')
  }

  throw new Error(errorMessage ? errorMessage : '')
}
